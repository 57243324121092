export default class WorkerInterval {
    worker = null;
    constructor(callback, interval) {
        const blob = new Blob([`
            const accurateInterval = (callback, interval) => {
                let startTime = Date.now();
                let timerId = null;
            
                const step = () => {
                    const now = Date.now();
                    const dt = now - startTime;
            
                    callback(dt);
        
                    startTime = now;
                    timerId = setTimeout(step, interval);
                }
            
                timerId = setTimeout(step, interval);
            
                return timerId;
            }
            
            accurateInterval((dt) => postMessage(dt), ${interval});
        `]);
        const workerScript = URL.createObjectURL(blob);
        this.worker = new Worker(workerScript);
        this.worker.onmessage = (event) => {
            callback(event.data);
        };
    }

    stop() {
        this.worker.terminate();
    }
}