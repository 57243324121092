import {createSlice} from "@reduxjs/toolkit";

export const initialState = {
    totalClicks: 0,
    totalGenerateCodeLines: 0,
    totalGenerateBugs: 0,
    totalSpentCodeLines: 0,
};

const reducers = {
    addTotalClicks: (state, action) => {
        state.totalClicks += 1;
    },
    addTotalGenerateCodeLines: (state, action) => {
        state.totalGenerateCodeLines += action.payload;
    },
    addTotalGenerateBugs: (state, action) => {
        state.totalGenerateBugs += action.payload;
    },
    addTotalSpentCodeLines: (state, action) => {
        state.totalSpentCodeLines += action.payload;
    },
};

const selectors = {
    selectTotalClicks: (state) => state.totalClicks,
    selectTotalGenerateCodeLines: (state) => state.totalGenerateCodeLines,
    selectTotalGenerateBugs: (state) => state.totalGenerateBugs,
    selectTotalSpentCodeLines: (state) => state.totalSpentCodeLines,
};

const statisticSlice = createSlice({
    name: "statistic",
    initialState,
    reducers,
    selectors,
});

export const {
    addTotalClicks,
    addTotalSpentCodeLines,
    addTotalGenerateCodeLines,
    addTotalGenerateBugs,
} = statisticSlice.actions;

export const {
    selectTotalClicks,
    selectTotalGenerateBugs,
    selectTotalGenerateCodeLines,
    selectTotalSpentCodeLines,
} = statisticSlice.selectors;

export default statisticSlice.reducer;

