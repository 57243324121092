import { styled } from '@mui/material/styles';
import BaseAccordionDetails from '@mui/material/AccordionDetails';

const AccordionDetails = styled(BaseAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function CustomAccordionDetails({children}) {
    return (
        <AccordionDetails>
            {children}
        </AccordionDetails>
    );
}

export default CustomAccordionDetails;