import { styled } from '@mui/material/styles';
import BaseAccordion from '@mui/material/Accordion';
import {useCallback, useState} from "react";
import CustomAccordionSummary from "./CustomAccordionSummary";
import CustomAccordionDetails from "./CustomAccordionDetails";

const Accordion = styled(BaseAccordion)(({ theme }) => ({
    backgroundColor: "rgba(51, 56, 67, 0.85)",
    color: "rgba(255, 255, 255, 0.85)",
    borderRadius: "8px !important",
    '&::before': {
        display: 'none',
    },
}));

function CustomAccordion({title, children, disabled = false}) {
    const [expanded, setExpanded] = useState(false);

    const handleChange = useCallback(() => setExpanded(prev => !prev), [setExpanded]);

    return (
        <Accordion disableGutters elevation={0} expanded={!disabled && expanded} onChange={handleChange} disabled={disabled}>
            <CustomAccordionSummary>{title}</CustomAccordionSummary>
            <CustomAccordionDetails>{children}</CustomAccordionDetails>
        </Accordion>
    );
}

export default CustomAccordion;