export const PRICE_INCREASE_IDS = {
    PERCENT_INCREASE_15: "PERCENT_INCREASE_15",
    PERCENT_INCREASE_500: "PERCENT_INCREASE_500",
    EXPONENTIAL_INCREASE: "EXPONENTIAL_INCREASE",
    EXPONENTIAL_INCREASE_C_2: "EXPONENTIAL_INCREASE_C_2",
}

export const INSTANT_ACTION_IDS = {
    DOUBLE_CLICKS: "DOUBLE_CLICKS",
    EMPLOYEES_INCREASE_PRODUCES_100: "EMPLOYEES_INCREASE_PRODUCES_100",
    EMPLOYEES_INCREASE_BUGS_100: "EMPLOYEES_INCREASE_BUGS_100",
    INCREMENT_MODIFIER_CODE_LINES_PER_SECONDS: "INCREMENT_MODIFIER_CODE_LINES_PER_SECONDS",
}

/*  INCREASE_PRODUCES_10_JMS: "INCREASE_PRODUCES_10_JMS",*/