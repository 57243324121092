import {useCallback, useState} from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    LinearProgress,
    Slide,
    Tooltip, useMediaQuery,
    useTheme
} from "@mui/material";
import ScienceIcon from '@mui/icons-material/Science';
import HelpIcon from "@mui/icons-material/Help";
import CloseIcon from "@mui/icons-material/Close";
import {useDispatch, useSelector} from "react-redux";
import {
    setResearchComplete,
    startProgress,
    selectResearchKeys,
} from "../../store/researchSlice";
import {getFunctionInstantAction, getNumberRuLocale, isFunction} from "../utils";
import TerminalIcon from "@mui/icons-material/Terminal";
import {isEqual, isNil, size} from "lodash";
import {LoadingButton} from "@mui/lab";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CustomAccordion from "./accordion/CustomAccordion";
import StarsIcon from '@mui/icons-material/Stars';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import BiotechIcon from '@mui/icons-material/Biotech';
import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined';

const PaperProps = {
    style: {
        backgroundColor: '#282c34',
        minHeight: "600px",
    },
};

const TransitionProps = { direction: "up", timeout: 500 };

const sx = {
    position: 'absolute',
    right: 8,
    top: 8,
    color: (theme) => theme.palette.grey[500],
};

const RewardComponent = ({research}) => {
    const produces = research?.produces;
    const description = research?.description;

    return (
        <div className="flex flex-col gap-1 text-base/[normal]">
            <span className="font-bold underline mr-1">Награда:</span>
            {!isNil(description) && <div>{description}</div>}
            {!isNil(produces) && <div><span>Прибавка {getNumberRuLocale(produces)}</span><TerminalIcon className="align-text-top mx-1"/><span>в секунду к общему значению</span></div>}
        </div>
    );
}

const sxButton = {
    "&.Mui-disabled": {
        color: "#909090"
    },
    ".MuiLoadingButton-loadingIndicator": {
        width: "100%",
        height: "100%",
    }
};

function ResearchTitle({title, isComplete, isProgress, condition}) {
    return (
        <div className="flex items-center justify-between w-full">
            <span className="font-bold text-lg/[normal]">{title}</span>
            {isProgress && <HourglassBottomIcon className="!text-2xl/[normal] text-yellow-400"/>}
            {isComplete && <CheckCircleIcon className="!text-2xl/[normal] text-green-600"/>}
            {!isComplete && condition &&  <StarsIcon className="!text-2xl/[normal] text-yellow-400"/>}
        </div>
    );
}

function ResearchComponent({keyValue, hasResearchesInProgress, index}) {
    const dispatch = useDispatch();
    const research = useSelector((state) => state?.research[keyValue]);
    const theme = useTheme();
    const value = Math.floor(research?.currenProgress / research?.price * 100);
    const handleStartProgress = useCallback(() => {
        dispatch(startProgress(keyValue));
    }, [dispatch, keyValue]);

    const handleGetReward = useCallback((research) => {
        const instantAction = getFunctionInstantAction(dispatch, research?.instantAction);
        dispatch(setResearchComplete({key: keyValue, value: true}));
        if (isFunction(instantAction)) {
            instantAction(research?.variableInstantAction);
        }
    }, [dispatch, keyValue]);

    return (
        <CustomAccordion title={<ResearchTitle title={`${index + 1}. ${research?.title}`} isProgress={research?.isProgress} isComplete={research?.isComplete} condition={research?.currenProgress >= research?.price}/>}>
            <div key={keyValue} className="flex flex-col gap-3">
                <div className="flex flex-col gap-1 text-base/[normal]">
                    <span className="font-bold underline mr-1">Стоимость исследования:</span>
                    <span className="flex gap-1 text-base/[normal]">{getNumberRuLocale(research?.price)}<TerminalIcon/></span>
                </div>
                <RewardComponent research={research}/>
                <LoadingButton
                    className="overflow-hidden"
                    loading={research?.isProgress}
                    loadingIndicator={<LinearProgress sx={{width: "100%", height: "100%"}} color="primary" value={value}
                                                      variant="determinate"/>}
                    variant="contained"
                    color="primary"
                    onClick={research?.currenProgress >= research?.price ? () => handleGetReward(research) : handleStartProgress}
                    disabled={(hasResearchesInProgress && research?.currenProgress < research?.price) || research?.isComplete}
                    sx={sxButton}
                >
                    {research?.isProgress ? <div style={{
                        color: theme.palette.primary.contrastText,
                        zIndex: 1000
                    }}>{`${value} %`}</div> : research?.isComplete
                        ? "Исследование завершено"
                        : research?.currenProgress >= research?.price
                            ? "Забрать награду" : "Начать исследование"}
                </LoadingButton>
            </div>
        </CustomAccordion>
    );
    /*    return (
            <div key={keyValue} className="flex flex-col p-4 rounded-[8px] gap-2 border-[1px] border-[white]/[0.12]">
                <div className="flex items-center justify-between">
                    <span className="font-bold text-lg/[normal]">{`${index + 1}. ${research?.title}`}</span>
                    {research?.isComplete && <CheckCircleIcon className="!text-2xl/[normal] text-green-600"/>}
                </div>
                <div className="flex gap-1 text-base/[normal]">
                    <span className="italic underline mr-1">Стоимость исследования:</span>
                    <span>{getNumberRuLocale(research?.price)}</span><TerminalIcon/>
                </div>
                <RewardComponent research={research}/>
                <LoadingButton
                    className="overflow-hidden"
                    loading={research?.isProgress}
                    loadingIndicator={<LinearProgress sx={{width: "100%", height: "100%"}} color="primary" value={value}
                                                      variant="determinate"/>}
                    variant="contained"
                    color="primary"
                    onClick={research?.currenProgress >= research?.price ? handleGetReward : handleStartProgress}
                    disabled={(hasResearchesInProgress && research?.currenProgress < research?.price) || research?.isComplete}
                    sx={sxButton}
                >
                    {research?.isProgress ? <div style={{
                        color: theme.palette.primary.contrastText,
                        zIndex: 1000
                    }}>{`${value} %`}</div> : research?.isComplete
                        ? "Исследование завершено"
                        : research?.currenProgress >= research?.price
                            ? "Забрать награду" : "Начать исследование"}
                </LoadingButton>
            </div>
        );*/
}

function ScienceButton() {
    const researchesKeys = useSelector(selectResearchKeys, isEqual);
    //const hasResearchesInProgress = size(Object.keys(researches).filter((key) => researches[key]?.isProgress)) !== 0;
    const hasResearchesInProgress = useSelector((state) => {
        const researches = state?.research;
        return size(Object.keys(researches).filter((key) => researches[key]?.isProgress)) !== 0;
    });

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = useState(false);

    const handleOpenDialog = useCallback(() => setOpen(true), []);

    const handleCloseDialog = useCallback(() => setOpen(false), []);

    return (
        <>
            <IconButton
                variant="contained"
                className="!rounded-[10%]"
                color="primary"
                onClick={handleOpenDialog}
                sx={{
                    "&:hover": {
                        backgroundColor: (theme) => theme.palette.primary.main,
                    },
                    backgroundColor: (theme) => theme.palette.primary.main,
                    color: (theme) => theme.palette.primary.contrastText
                }}
            >
                <BiotechIcon className="!text-2xl"/>
            </IconButton>
            <Dialog
                TransitionComponent={Slide}
                TransitionProps={TransitionProps}
                fullWidth
                maxWidth="sm"
                open={open}
                PaperProps={{
                    style: {
                        backgroundColor: '#282c34',
                        height: fullScreen ? "100%" : "600px",
                    },
                }}
                fullScreen={fullScreen}
            >
                <DialogTitle className="text-white/[0.85]">
                    <div className="flex gap-1 items-center">
                        <span>Исследование</span>
                        <Tooltip
                            enterTouchDelay={0}
                            placement="bottom-start"
                            title="В этом модальном окне вы можете изучить новые технологии, которые будут приносить полезные эффекты"
                        >
                            <HelpIcon className="!text-xl"/>
                        </Tooltip>
                    </div>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseDialog}
                    sx={sx}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers className="text-white/[0.85] flex flex-col gap-2 !p-6">
                    {researchesKeys.map((key, index) => <ResearchComponent key={key} index={index} keyValue={key} hasResearchesInProgress={hasResearchesInProgress} />)}
                </DialogContent>
            </Dialog>
        </>
    );
}

export default ScienceButton;