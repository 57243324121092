import {INSTANT_ACTION_IDS, PRICE_INCREASE_IDS} from "./constants";
import {incrementModifierCodeLinesPerSeconds, multipleCodeLinesPerClick} from "../../store/baseSlice";
import {multipleBugsForEmployees, multipleProducesForEmployees} from "../../store/improvementsSlice";
import {round} from "lodash";

export function getNumberBase(number, locale, options = {}) {
    return new Intl.NumberFormat(locale, options).format(number);
}

export function getNumberRuLocale(number, isShort = false, options = {}) {
    return Math.abs(number) >= 1000000 ? formatNumber(number, isShort, options) : getNumberBase(number, 'ru-RU');
}

export function getFunctionNewPrice(type) {
    switch (type) {
        case PRICE_INCREASE_IDS.PERCENT_INCREASE_15:
            return (object) => Math.ceil(object?.price * 1.15);
        case PRICE_INCREASE_IDS.PERCENT_INCREASE_500:
            return (object) => Math.ceil(object?.price * 5);
        case PRICE_INCREASE_IDS.EXPONENTIAL_INCREASE:
            return (object) => exponentialIncrease(object?.basePrice, object?.count);
        case PRICE_INCREASE_IDS.EXPONENTIAL_INCREASE_C_2:
            return (object) => exponentialIncrease(object?.basePrice, object?.count, 2);
        default:
            return undefined;
    }
}

export function exponentialIncrease(startValue, count, coefficient = 5) {
    let growthRate = count > 0 ? Math.log(coefficient * count) : 1;
    return Math.ceil(startValue * Math.exp(growthRate * count));
}

export function getFunctionInstantAction(dispatch, type) {
    switch (type) {
        case INSTANT_ACTION_IDS.DOUBLE_CLICKS:
            return (variableInstantAction) => dispatch(multipleCodeLinesPerClick(2));
        case INSTANT_ACTION_IDS.EMPLOYEES_INCREASE_PRODUCES_100:
            return (variableInstantAction) => dispatch(multipleProducesForEmployees({
                employees: variableInstantAction,
                coefficient: 2,
            }));
        case INSTANT_ACTION_IDS.EMPLOYEES_INCREASE_BUGS_100:
            return (variableInstantAction) => dispatch(multipleBugsForEmployees({
                employees: variableInstantAction,
                coefficient: 2,
            }));
        case INSTANT_ACTION_IDS.INCREMENT_MODIFIER_CODE_LINES_PER_SECONDS:
            return (variableInstantAction) => dispatch(incrementModifierCodeLinesPerSeconds(variableInstantAction))
        default:
            return undefined;
    }
}

/*        case INSTANT_ACTION_IDS.INCREASE_PRODUCES_10_JMS:
            return () => {
                dispatch(updatePercentProducesForEmployees({
                    employees: [EMPLOYEES_IDS.JUNIOR_DEVELOPER, EMPLOYEES_IDS.MIDDLE_DEVELOPER, EMPLOYEES_IDS.SENIOR_DEVELOPER],
                    percent: 0.1,
                }));
            }*/

export function isFunction(variableToCheck) {
    return (typeof variableToCheck === 'function');
}

export function parseExpression(str, params) {
    const names = Object.keys(params);
    const values = Object.values(params);
    return Function(...names, `'use strict'; return (${str})`)(...values);
}

export function formatNumber(number, isShort = false, options = {}) {
    const suffixesShort = ['', ' млн', ' млрд', ' трлн', ' квадр', ' квинт', ' секст', ' септ', ' окт', ' нон'];
    const suffixesFull = ['', ' миллион', ' миллиард', ' триллион', ' квадриллион', ' квинтиллион', ' секстиллион', 'септиллион', 'октиллион', 'нониллион'];
    const suffixes = isShort ? suffixesShort : suffixesFull;
    //let length = size(toString(number));
    let numStr = number.toLocaleString('fullwide', {useGrouping: false});
    let length = numStr.length;

    if (length % 3 === 0) {
        length -= 1;
    }

    let suffixIndex = Math.floor(length / 3) - 1;

    if (suffixIndex >= suffixes.length) {
        suffixIndex = suffixes.length - 1;
    }

    const formattedNumber = round(number / Math.pow(1000, suffixIndex + 1), 2);
    return `${getNumberBase(formattedNumber, 'ru-RU', options)}${suffixes[suffixIndex]}`;
}