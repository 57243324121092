import {useCallback} from "react";
import { useSnackbar } from 'notistack';
import {Alert, Slide} from "@mui/material";
import {MESSAGE_VARIANTS} from "../constants";

const anchorOrigin = { vertical: "bottom", horizontal: "center" };
const DEFAULT_PROPS = { autoHideDuration: 5000 };

function useMessage(props = DEFAULT_PROPS) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    return useCallback((message = '', messageVariant = MESSAGE_VARIANTS.INFO) => {
        enqueueSnackbar(message, {
            anchorOrigin,
            autoHideDuration: props.autoHideDuration,
            TransitionComponent: Slide,
            TransitionProps: {
                direction: "up",
            },
            content: (key) => (
                <Alert
                    id={key}
                    className="w-[320px]"
                    color={messageVariant}
                    variant="filled"
                    severity={messageVariant}
                    onClose={() => closeSnackbar(key)}
                    sx={{".MuiAlert-action": { paddingTop: "0", alignItems: "center" }, ".MuiAlert-icon": { padding: "0", alignItems: "center" }}}
                >
                    {message}
                </Alert>
            ),
        });
    }, [enqueueSnackbar, props.autoHideDuration]);
}

export default useMessage;