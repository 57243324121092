import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './App.css';
import {Provider} from 'react-redux';
import store, {configureStoreAsync} from './store';
import useMessage from "./components/message/hooks/useMessage";
import {throttle} from "lodash";
import {getNumberRuLocale} from "./components/utils";

const root = ReactDOM.createRoot(document.getElementById('root'));

configureStoreAsync().then(store => {
    store.subscribe(throttle(() => {
        const state = store.getState();

        if(state?.settings?.showCodeLinesInTab) {
            document.title = `${getNumberRuLocale(Math.floor(state?.base?.codeLines))} - Code Clicker`
        }
    }, 10000));

    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <App />
            </Provider>
        </React.StrictMode>
    );
});


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
