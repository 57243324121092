import {useCallback, useState} from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Slide,
    Tooltip,
    useMediaQuery,
    useTheme
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CloseIcon from "@mui/icons-material/Close";
import TerminalIcon from "@mui/icons-material/Terminal";
import HelpIcon from '@mui/icons-material/Help';
import {useDispatch, useSelector} from "react-redux";
import {REWARD_IDS, selectTasks, setTaskComplete} from "../../store/tasksSlice";
import {getNumberRuLocale, parseExpression} from "../utils";
import {incrementCodeLines, incrementCodeLinesPerClickFromEmployees} from "../../store/baseSlice";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CustomAccordion from "./accordion/CustomAccordion";
import StarsIcon from '@mui/icons-material/Stars';

const RewardComponent = ({task}) => {
    const typeReward = {
        [REWARD_IDS.CODE_LINES]: (value) => (<div><span>Прибавка {getNumberRuLocale(value)}</span><TerminalIcon className="align-text-top mx-1"/><span>к общему значению</span></div>),
        [REWARD_IDS.CODE_LINES_PER_CLICK_FROM_EMPLOYEES]: (value) => (<div><span>Прибавка +{getNumberRuLocale(value)}</span><TerminalIcon className="align-text-top mx-1"/><span>за каждого нанятого сотрудника к строчкам кода за клик</span></div>),
    };

    return (
        <div className="flex flex-col gap-1 text-base/[normal]">
            <span className="font-bold underline mr-1">Награда:</span>
            {typeReward[task?.typeReward](task?.valueReward)}
        </div>
    );
}

const PaperProps = {
    style: {
        backgroundColor: '#282c34',
        minHeight: "600px",
    },
};

const TransitionProps = {direction: "up", timeout: 500};

const sx = {
    position: 'absolute',
    right: 8,
    top: 8,
    color: (theme) => theme.palette.grey[500],
};

const sxButton = {
    "&.Mui-disabled": {
        color: "#909090"
    }
};

function getReward(key, task) {
    switch (task?.typeReward) {
        case REWARD_IDS.CODE_LINES:
            return (dispatch) => {
                dispatch(incrementCodeLines(task?.valueReward ?? 0));
                dispatch(setTaskComplete({key, value: true}));
            };
        case REWARD_IDS.CODE_LINES_PER_CLICK_FROM_EMPLOYEES:
            return (dispatch) => {
                dispatch(incrementCodeLinesPerClickFromEmployees(task?.valueReward ?? 0));
                dispatch(setTaskComplete({key, value: true}));
            };
        default:
            return () => {
            };
    }
}

function TaskTitle({title, isComplete, condition}) {
    return (
        <div className="flex items-center justify-between w-full">
            <span className="font-bold text-lg/[normal]">{title}</span>
            {isComplete && <CheckCircleIcon className="!text-2xl/[normal] text-green-600"/>}
            {!isComplete && condition && <StarsIcon className="!text-2xl/[normal] text-yellow-400"/>}
        </div>
    );
}

function TaskComponent({keyValue, task, index}) {
    const dispatch = useDispatch();
    const condition = useSelector((state) =>
        task?.conditionExpression
            ? parseExpression(task?.conditionExpression, {key: keyValue, state})
            : false
    );
    const progress = useSelector((state) =>
        task?.progressExpression
            ? parseExpression(task?.progressExpression, {key: keyValue, state})
            : "Прогресс не отслеживается"
    );

    return (
        <CustomAccordion title={<TaskTitle title={`${index + 1}. ${task?.title}`} isComplete={task?.isComplete} condition={condition}/>}>
            <div key={keyValue} className="flex flex-col gap-3">
                <div className="flex flex-col gap-1 text-base/[normal]">
                    <span className="font-bold underline mr-1">Описание:</span>
                    {task?.description}
                </div>
                <div className="flex flex-col gap-1 text-base/[normal]">
                    <span className="font-bold underline mr-1">Условие:</span>
                    {task?.condition}
                </div>
                <RewardComponent task={task}/>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => dispatch(getReward(keyValue, task))}
                    disabled={!condition || task?.isComplete}
                    sx={sxButton}
                >
                    {task?.isComplete
                        ? "Награда получена" :
                        condition
                            ? "Забрать награду"
                            : progress
                    }
                </Button>
            </div>
        </CustomAccordion>
    );
    /*    return (
        <div key={keyValue} className="flex flex-col p-4 rounded-[8px] gap-2 border-[1px] border-[white]/[0.12]">
                    <div className="flex items-center justify-between">
                        <span className="font-bold text-lg/[normal]">{`${index + 1}. ${task?.title}`}</span>
                        {task?.isComplete && <CheckCircleIcon className="!text-2xl/[normal] text-green-600" />}
                    </div>
                    <div className="flex gap-1 text-base/[normal]">
                        <span className="italic underline mr-1">Условие:</span>
                        {task?.description}
                    </div>
                    <RewardComponent task={task}/>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => dispatch(getReward(keyValue, task))}
                        disabled={!condition || task?.isComplete}
                        sx={sxButton}
                    >
                        {task?.isComplete
                            ? "Награда получена" :
                            condition
                                ? "Забрать награду"
                                : progress
                        }
                    </Button>
                </div>
            );*/
}

function TasksButton() {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const tasks = useSelector(selectTasks);

    const [open, setOpen] = useState(false);

    const handleOpenDialog = useCallback(() => setOpen(true), [setOpen]);

    const handleCloseDialog = useCallback(() => setOpen(false), [setOpen]);

    return (
        <>
            <IconButton
                variant="contained"
                className="!rounded-[10%]"
                color="primary"
                onClick={handleOpenDialog}
                sx={{
                    "&:hover": {
                        backgroundColor: (theme) => theme.palette.primary.main,
                    },
                    backgroundColor: (theme) => theme.palette.primary.main,
                    color: (theme) => theme.palette.primary.contrastText
                }}
            >
                <AssignmentIcon className="!text-2xl"/>
            </IconButton>
            <Dialog
                TransitionComponent={Slide}
                TransitionProps={TransitionProps}
                fullWidth
                maxWidth="sm"
                open={open}
                PaperProps={{
                    style: {
                        backgroundColor: '#282c34',
                        height: fullScreen ? "100%" : "600px",
                    },
                }}
                fullScreen={fullScreen}
            >
                <DialogTitle className="text-white/[0.85]">
                    <div className="flex gap-1 items-center">
                        <span>Задачи</span>
                        <Tooltip
                            enterTouchDelay={0}
                            placement="bottom-start"
                            title="В этом модальном окне вы можете выполнять различные задачи и получать ценные награды"
                        >
                            <HelpIcon className="!text-xl"/>
                        </Tooltip>
                    </div>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseDialog}
                    sx={sx}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogContent dividers className="text-white/[0.85] flex flex-col gap-2 !p-6">
                    {Object.keys(tasks).map((key, index) => (<TaskComponent key={key} index={index} keyValue={key} task={tasks[key]}/>))}
                </DialogContent>
            </Dialog>
        </>
    );
}

export default TasksButton;
