import {createSlice} from "@reduxjs/toolkit";
import {EMPLOYEES_IDS, IMPROVEMENT_IDS} from "./improvementsSlice";
import {getNumberRuLocale} from "../components/utils";

export const TASK_IDS = {
    TASK_1: "TASK_1",
    TASK_2: "TASK_2",
    TASK_3: "TASK_3",
    TASK_4: "TASK_4",
    TASK_5: "TASK_5",
    TASK_6: "TASK_6",
    TASK_7: "TASK_7",
    TASK_8: "TASK_8",
    TASK_9: "TASK_9",
}

export const REWARD_IDS = {
    CODE_LINES: "CODE_LINES",
    CODE_LINES_PER_CLICK_FROM_EMPLOYEES: "CODE_LINES_PER_CLICK_FROM_EMPLOYEES",
}

export const initialState = {
    [TASK_IDS.TASK_1]: {
        title: "Первый кусок кода",
        condition: `Необходимо сделать ${getNumberRuLocale(100, true)} кликов`,
        description: "На старте своей карьеры вы решили написать свой первый кусок кода, но это только начало...",
        isComplete: false,
        typeReward: REWARD_IDS.CODE_LINES_PER_CLICK_FROM_EMPLOYEES,
        valueReward: 0.1,
        conditionExpression: "state?.statistic?.totalClicks >= 100",
        progressExpression: "`${state?.statistic?.totalClicks}/100`",
    },
    [TASK_IDS.TASK_2]: {
        title: "Основы алгоритмов",
        condition: `Необходимо сделать ${getNumberRuLocale(1000, true)} кликов`,
        description: "После долгих усилий вы изучили основы алгоритмов и даже реализовали некоторые из них на практике",
        isComplete: false,
        typeReward: REWARD_IDS.CODE_LINES_PER_CLICK_FROM_EMPLOYEES,
        valueReward: 0.5,
        conditionExpression: "state?.statistic?.totalClicks >= 1000",
        progressExpression: "`${state?.statistic?.totalClicks}/1000`",
    },
    [TASK_IDS.TASK_3]: {
        title: "Отладка кода",
        condition: `Необходимо сделать ${getNumberRuLocale(10000, true)} кликов`,
        description: "В ходе практики вы нашли множество багов в своем коде. Что ж предстоит долгая ночь...",
        isComplete: false,
        typeReward: REWARD_IDS.CODE_LINES_PER_CLICK_FROM_EMPLOYEES,
        valueReward: 1,
        conditionExpression: "state?.statistic?.totalClicks >= 10000",
        progressExpression: "`${state?.statistic?.totalClicks}/10000`",
    },
    [TASK_IDS.TASK_4]: {
        title: "Построение первого прототипа",
        condition: `Необходимо сделать ${getNumberRuLocale(100000, true)} кликов`,
        description: "Ваш упроный труд окупился и на свет появился первый прототип. Столо ли это стольких усилий?!",
        isComplete: false,
        typeReward: REWARD_IDS.CODE_LINES_PER_CLICK_FROM_EMPLOYEES,
        valueReward: 5,
        conditionExpression: "state?.statistic?.totalClicks >= 100000",
        progressExpression: "`${state?.statistic?.totalClicks}/100000`",
    },
    [TASK_IDS.TASK_5]: {
        title: "Тестирование функционала",
        condition: `Необходимо сделать ${getNumberRuLocale(1000000, true)} кликов`,
        description: "Воодушивившись результатом, вы решаетесь на тестирование функционала, скрестив пальцы",
        isComplete: false,
        typeReward: REWARD_IDS.CODE_LINES_PER_CLICK_FROM_EMPLOYEES,
        valueReward: 10,
        conditionExpression: "state?.statistic?.totalClicks >= 1000000",
        progressExpression: "`${state?.statistic?.totalClicks}/1000000`",
    },
    [TASK_IDS.TASK_6]: {
        title: "Запуск бета-версии",
        condition: `Необходимо сделать ${getNumberRuLocale(10000000, true)} кликов`,
        description: "Пришла пора показать наше многострадальное приложение миру. Для этого вы занимаетесь подготовкой и запуском",
        isComplete: false,
        typeReward: REWARD_IDS.CODE_LINES_PER_CLICK_FROM_EMPLOYEES,
        valueReward: 50,
        conditionExpression: "state?.statistic?.totalClicks >= 10000000",
        progressExpression: "`${state?.statistic?.totalClicks}/10000000`",
    },
    [TASK_IDS.TASK_7]: {
        title: "Масштабирование сервера",
        condition: `Необходимо сделать ${getNumberRuLocale(100000000, true)} кликов`,
        description: "Вы неожидали такого ошеламительного успеха и впопыхах занимаетесь масштабированием серверов",
        isComplete: false,
        typeReward: REWARD_IDS.CODE_LINES_PER_CLICK_FROM_EMPLOYEES,
        valueReward: 100,
        conditionExpression: "state?.statistic?.totalClicks >= 100000000",
        progressExpression: "`${state?.statistic?.totalClicks}/100000000`",
    },
    [TASK_IDS.TASK_8]: {
        title: "Оптимизация производительности",
        condition: `Необходимо сделать ${getNumberRuLocale(1000000000, true)} кликов`,
        description: "В ходе большого наплыва пользователей, вскрылись проблемы с производительность приложения. Вы готовы принять этот вызов?",
        isComplete: false,
        typeReward: REWARD_IDS.CODE_LINES_PER_CLICK_FROM_EMPLOYEES,
        valueReward: 500,
        conditionExpression: "state?.statistic?.totalClicks >= 1000000000",
        progressExpression: "`${state?.statistic?.totalClicks}/1000000000`",
    },
    [TASK_IDS.TASK_9]: {
        title: "Релиз полной версии",
        condition: `Необходимо сделать ${getNumberRuLocale(10000000000, true)} кликов`,
        description: "Наконец пришло время сделать это. Версия 1.0.0 вышла в свет. Что вы ощущаете после этого?",
        isComplete: false,
        typeReward: REWARD_IDS.CODE_LINES_PER_CLICK_FROM_EMPLOYEES,
        valueReward: 1000,
        conditionExpression: "state?.statistic?.totalClicks >= 10000000000",
        progressExpression: "`${state?.statistic?.totalClicks}/10000000000`",
    },
};

/*[TASK_IDS.FIRST_TASK]: {
    title: "Задача № 1",
        description: "Необходимо сделать 100 кликов",
        isComplete: false,
        typeReward: REWARD_IDS.CODE_LINES,
        valueReward: 1000000,
        conditionExpression: "state?.statistic?.totalClicks >= 100",
        progressExpression: "`${state?.statistic?.totalClicks}/100`",
},
[TASK_IDS.SECOND_TASK]: {
    title: "Новая команда",
        description: "Необходимо нанять 5 младших разработчиков",
        isComplete: false,
        typeReward: REWARD_IDS.CODE_LINES,
        valueReward: 5000000,
        conditionExpression: `state?.improvements?.${IMPROVEMENT_IDS?.EMPLOYEES}?.values?.${EMPLOYEES_IDS.JUNIOR_DEVELOPER}?.count >= 5`,
        progressExpression: `\`\${state?.improvements?.${IMPROVEMENT_IDS?.EMPLOYEES}?.values?.${EMPLOYEES_IDS.JUNIOR_DEVELOPER}?.count}/5\``,
},
[TASK_IDS.THIRD_TASK]: {
    title: "Усиление команды",
        description: "Необходимо нанять 5 средних разработчиков",
        isComplete: false,
        typeReward: REWARD_IDS.CODE_LINES,
        valueReward: 10000000,
        conditionExpression: `state?.improvements?.${IMPROVEMENT_IDS?.EMPLOYEES}?.values?.${EMPLOYEES_IDS.MIDDLE_DEVELOPER}?.count >= 5`,
        progressExpression: `\`\${state?.improvements?.${IMPROVEMENT_IDS?.EMPLOYEES}?.values?.${EMPLOYEES_IDS.MIDDLE_DEVELOPER}?.count}/5\``,
}*/

const reducers = {
    setTaskComplete: (state, action) => {
        state[action.payload?.key].isComplete = action.payload?.value;
    }
};

const selectors = {
    selectTasks: (state) => state,
};

const tasksSlice = createSlice({
    name: "tasks",
    initialState,
    reducers,
    selectors,
});

export const {
    setTaskComplete,
} = tasksSlice.actions;

export const {
    selectTasks,
} = tasksSlice.selectors;

export default tasksSlice.reducer;

