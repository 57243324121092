import {IconButton, useTheme} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import {useCallback, useEffect} from "react";
import useMessage from "../message/hooks/useMessage";
import {store} from "../../store";
import {useSelector} from "react-redux";
import {selectEnableAutoSaveConfig} from "../../store/settingsSlice";

function SaveConfigButton() {
    const enqueueMessage = useMessage();
    const enableAutoSaveConfig = useSelector(selectEnableAutoSaveConfig);

    useEffect(() => {
        const interval = enableAutoSaveConfig ? setInterval(() => saveConfig(), 120000) : undefined;
        return () => interval && clearInterval(interval);
    }, [enableAutoSaveConfig]);

    const saveConfig = useCallback(() => {
        localStorage.setItem('config', JSON.stringify(store?.getState()));
        enqueueMessage("Игра сохранена");
    }, []);

    return (
        <IconButton
            variant="contained"
            className="!rounded-[10%]"
            color="primary"
            onClick={saveConfig}
            sx={{
                "&:hover": {
                    backgroundColor: (theme) => theme.palette.primary.main,
                },
                backgroundColor: (theme) => theme.palette.primary.main,
                color: (theme) => theme.palette.primary.contrastText
            }}
        >
            <SaveIcon className="!text-2xl"/>
        </IconButton>
    );
}

export default SaveConfigButton;