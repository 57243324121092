import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import BaseAccordionSummary from '@mui/material/AccordionSummary';

const AccordionSummary = styled(BaseAccordionSummary)(({ theme }) => ({
    backgroundColor: 'rgba(0, 0, 0, .05)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

function CustomAccordionSummary({children}) {
   return (
        <AccordionSummary expandIcon={<ArrowForwardIosSharpIcon className="text-white/[0.85]" sx={{ fontSize: '0.9rem' }} />}>
            {children}
        </AccordionSummary>
   );
}

export default CustomAccordionSummary;