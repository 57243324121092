import React from 'react';
import TasksButton from "./TasksButton";
import SaveConfigButton from "./SaveConfigButton";
import StatisticButton from "./StatisticButton";
import ShopButton from "./ShopButton";
import packageJson from '../../../package.json';
import ScienceButton from "./ScienceButton";
import SettingsButton from "./SettingsButton";

function MenuBar() {
    return (
        <div className="bottom-0 absolute p-4 w-full grid items-end grid-cols-[1fr] gap-x-4">
            <div className="gap-2 flex row-start-1 row-end-1">
                <ShopButton />
                <TasksButton />
                <ScienceButton />
                <StatisticButton />
                <SettingsButton />
                <SaveConfigButton />
            </div>
            <div className="text-white/[0.85] text-base text-right flex row-start-1 row-end-1">Версия {packageJson?.version}</div>
        </div>
    );
}

export default MenuBar;

