import {Dialog, DialogContent, DialogTitle, IconButton, Slide, Tooltip, useMediaQuery, useTheme} from "@mui/material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CloseIcon from "@mui/icons-material/Close";
import {useCallback, useState} from "react";
import HelpIcon from "@mui/icons-material/Help";
import {
    selectTotalClicks,
    selectTotalGenerateBugs,
    selectTotalGenerateCodeLines,
    selectTotalSpentCodeLines
} from "../../store/statisticSlice";
import {useSelector} from "react-redux";
import {getNumberRuLocale} from "../utils";
import {
    selectCodeLinesPerClick, selectCodeLinesPerSeconds, selectModifierCodeLinesPerClick,
    selectModifierCodeLinesPerSeconds,
    selectModifierTotalBugsPerSeconds, selectTotalBugsPerSeconds
} from "../../store/baseSlice";
import {IMPROVEMENT_IDS} from "../../store/improvementsSlice";

const PaperProps = {
    style: {
        backgroundColor: '#282c34',
        minHeight: "600px",
    },
};

const TransitionProps = {direction: "up", timeout: 500};

const sx = {
    position: 'absolute',
    right: 8,
    top: 8,
    color: (theme) => theme.palette.grey[500],
};

function CounterStatisticComponent() {
    const totalGenerateCodeLines = useSelector(selectTotalGenerateCodeLines);

    return (
        <span>Всего написано строк: {getNumberRuLocale(totalGenerateCodeLines.toFixed(1))}</span>
    );
}

function TotalBugsStatisticComponent() {
    const totalGenerateBugs = useSelector(selectTotalGenerateBugs);

    return (
        <span>Всего сгенерировано багов: {getNumberRuLocale(totalGenerateBugs.toFixed(1))}</span>
    );
}

function StatisticButton() {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const totalClicks = useSelector(selectTotalClicks);
    const totalSpentCodeLines = useSelector(selectTotalSpentCodeLines);
    const codeLinesPerSeconds = useSelector(selectCodeLinesPerSeconds);
    const totalBugsPerSeconds = useSelector(selectTotalBugsPerSeconds);
    const modifierCodeLinesPerSeconds = useSelector(selectModifierCodeLinesPerSeconds);
    const modifierTotalBugsPerSeconds = useSelector(selectModifierTotalBugsPerSeconds);
    const codeLinesPerClick = useSelector(selectCodeLinesPerClick);
    const codeLinesPerClickFromEmployees = useSelector((state) => {
        const codeLinesPerClickFromEmployees = state?.base?.codeLinesPerClickFromEmployees;
        const employees = state?.improvements?.[IMPROVEMENT_IDS.EMPLOYEES]?.values;
        const employeesCount = Object.keys(employees)?.reduce((acc, cur) => {
            const count = employees[cur]?.count;
            return acc + count;
        }, 0);
        return codeLinesPerClickFromEmployees * employeesCount;
    });
    const modifierCodeLinesPerClick = useSelector(selectModifierCodeLinesPerClick);
    const totalCodeLinesPerClicks =  (codeLinesPerClick + codeLinesPerClickFromEmployees) * modifierCodeLinesPerClick;
    const [open, setOpen] = useState(false);

    const handleOpenDialog = useCallback(() => setOpen(true), []);

    const handleCloseDialog = useCallback(() => setOpen(false), []);

    return (
        <>
            <IconButton
                variant="contained"
                className="!rounded-[10%]"
                color="primary"
                onClick={handleOpenDialog}
                sx={{
                    "&:hover": {
                        backgroundColor: (theme) => theme.palette.primary.main,
                    },
                    backgroundColor: (theme) => theme.palette.primary.main,
                    color: (theme) => theme.palette.primary.contrastText
                }}
            >
                <AssessmentIcon className="!text-2xl"/>
            </IconButton>
            <Dialog
                TransitionComponent={Slide}
                TransitionProps={TransitionProps}
                fullWidth
                maxWidth="sm"
                open={open}
                PaperProps={{
                    style: {
                        backgroundColor: '#282c34',
                        height: fullScreen ? "100%" : "600px",
                    },
                }}
                fullScreen={fullScreen}
            >
                <DialogTitle className="text-white/[0.85]">
                    <div className="flex gap-1 items-center">
                        <span>Статистика</span>
                        <Tooltip enterTouchDelay={0}
                                 placement="bottom-start"
                                 title="В этом модальном окне вы можете посмотреть полную статистику по игре"
                        >
                            <HelpIcon className="!text-xl"/>
                        </Tooltip>
                    </div>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseDialog}
                    sx={sx}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogContent dividers className="flex flex-col text-white/[0.85] gap-2">
                    <span>Всего кликов: {getNumberRuLocale(totalClicks)}</span>
                    <CounterStatisticComponent/>
                    <span>Всего утеряно строк: {getNumberRuLocale(totalSpentCodeLines)}</span>
                    <TotalBugsStatisticComponent/>
                    <span>Строк кода в секунду: {getNumberRuLocale(codeLinesPerSeconds)} (множитель: {getNumberRuLocale(Math.floor(modifierCodeLinesPerSeconds * 100))} %)</span>
                    <span>Багов в секунду: {getNumberRuLocale(totalBugsPerSeconds)} (множитель: {getNumberRuLocale(Math.floor(modifierTotalBugsPerSeconds * 100))} %)</span>
                    <span>Количество строк за клик: {getNumberRuLocale(totalCodeLinesPerClicks)} (множитель: {getNumberRuLocale(Math.floor(modifierCodeLinesPerClick * 100))} %)</span>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default StatisticButton;