import {combineReducers, configureStore} from "@reduxjs/toolkit";
import baseReducer from "./baseSlice";
import improvementsReducer from "./improvementsSlice";
import statisticReducer from "./statisticSlice";
import tasksReducer from "./tasksSlice";
import settingsReducer from "./settingsSlice";
import researchReducer from "./researchSlice";
import {defaultsDeep} from "lodash";
import {initState} from "./initStates";

const rootReducer = combineReducers({
    base: baseReducer,
    improvements: improvementsReducer,
    statistic: statisticReducer,
    tasks: tasksReducer,
    settings: settingsReducer,
    research: researchReducer,
});

export let store = null;

export const configureStoreAsync = () => {
    return new Promise((resolve, reject) => {
        const configFromLocalStorage = localStorage.getItem('config');
        const options = {
            reducer: rootReducer,
            middleware: (getDefaultMiddleware) => [...getDefaultMiddleware({immutableCheck: false, serializableCheck: false})]
        };

        if(configFromLocalStorage) {
            options.preloadedState = defaultsDeep(JSON.parse(configFromLocalStorage), initState);
        }

        store = configureStore(options);

        resolve(store);
    });
};
