import {createSlice} from "@reduxjs/toolkit";

export const initialState = {
    codeLines: 0,
    codeLinesPerSeconds: 0,
    codeLinesPerClick: 1,
    totalBugs: 0,
    totalBugsPerSeconds: 0,
    overallEfficiency: 1,
    modifierCodeLinesPerSeconds: 1,
    modifierTotalBugsPerSeconds: 1,
    modifierCodeLinesPerClick: 1,
    codeLinesPerClickFromEmployees: 0,
};

const reducers = {
    incrementCodeLines: (state, action) => {
        state.codeLines += action.payload;
    },
    decrementCodeLines: (state, action) => {
        state.codeLines -= action.payload;
    },
    incrementTotalBugs: (state, action) => {
        if(state.totalBugs + action.payload <= 0) {
            state.totalBugs = 0;
        } else {
            state.totalBugs += action.payload;
        }
    },
    decrementTotalBugs: (state, action) => {
        if(state.totalBugs - action.payload <= 0) {
            state.totalBugs = 0;
        } else {
            state.totalBugs -= action.payload;
        }
    },
    setOverallEfficiency: (state, action) => {
        if(action.payload <= 0) {
            state.overallEfficiency = 0;
        } else {
            state.overallEfficiency = action.payload;
        }
    },
    setCodeLinesPerSeconds: (state, action) => {
        state.codeLinesPerSeconds = action.payload;
    },
    setTotalBugsPerSeconds: (state, action) => {
        state.totalBugsPerSeconds = action.payload;
    },
    setTotalBugsAndCodeLines: (state, action) => {
        state.codeLinesPerSeconds = action.payload?.codeLinesPerSeconds;
        state.totalBugsPerSeconds = action.payload?.totalBugsPerSeconds;
    },
    multipleCodeLinesPerClick: (state, action) => {
        state.modifierCodeLinesPerClick = state.modifierCodeLinesPerClick * action.payload;
    },
    incrementModifierCodeLinesPerSeconds: (state, action) => {
        state.modifierCodeLinesPerSeconds += action.payload;
    },
    incrementCodeLinesPerClickFromEmployees: (state, action) => {
        state.codeLinesPerClickFromEmployees += action.payload;
    },
};

const selectors = {
    selectCodeLines: (state) => state.codeLines,
    selectCodeLinesPerSeconds: (state) => state.codeLinesPerSeconds,
    selectCodeLinesPerClick: (state) => state.codeLinesPerClick,
    selectTotalBugs: (state) => state.totalBugs,
    selectTotalBugsPerSeconds: (state) => state.totalBugsPerSeconds,
    selectOverallEfficiency: (state) => state.overallEfficiency,
    selectModifierCodeLinesPerSeconds: (state) => state.modifierCodeLinesPerSeconds,
    selectModifierTotalBugsPerSeconds: (state) => state.modifierTotalBugsPerSeconds,
    selectModifierCodeLinesPerClick: (state) => state.modifierCodeLinesPerClick,
};

const baseSlice = createSlice({
    name: "base",
    initialState,
    reducers,
    selectors,
});

export const {
    incrementCodeLines,
    decrementCodeLines,
    incrementTotalBugs,
    decrementTotalBugs,
    setOverallEfficiency,
    setCodeLinesPerSeconds,
    setTotalBugsPerSeconds,
    setTotalBugsAndCodeLines,
    multipleCodeLinesPerClick,
    incrementModifierCodeLinesPerSeconds,
    incrementCodeLinesPerClickFromEmployees,
} = baseSlice.actions;

export const {
    selectCodeLines,
    selectCodeLinesPerSeconds,
    selectCodeLinesPerClick,
    selectTotalBugs,
    selectTotalBugsPerSeconds,
    selectOverallEfficiency,
    selectModifierCodeLinesPerSeconds,
    selectModifierTotalBugsPerSeconds,
    selectModifierCodeLinesPerClick,
} = baseSlice.selectors;

export default baseSlice.reducer;

