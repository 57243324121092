import {createSlice} from "@reduxjs/toolkit";

export const initialState = {
    showCodeLinesInTab: false,
    bgColorTheme: undefined,
    enableAutoSaveConfig: true,
};

const reducers = {
    toggleShowCodeLinesInTab: (state, action) =>{
        state.showCodeLinesInTab = !state.showCodeLinesInTab;
    },
    setBgColorTheme: (state, action) => {
        state.bgColorTheme = action.payload;
    },
    resetBgColorTheme: (state, action) => {
        state.bgColorTheme = initialState.bgColorTheme;
    },
    toggleEnableAutoSaveConfig: (state, action) => {
        state.enableAutoSaveConfig = !state.enableAutoSaveConfig;
    }
};

const selectors = {
    selectShowCodeLinesInTab: (state) => state.showCodeLinesInTab,
    selectBgColorTheme: (state) => state.bgColorTheme,
    selectEnableAutoSaveConfig: (state) => state.enableAutoSaveConfig,
};

const settingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers,
    selectors,
});

export const {
    toggleShowCodeLinesInTab,
    setBgColorTheme,
    resetBgColorTheme,
    toggleEnableAutoSaveConfig,
} = settingsSlice.actions;

export const {
    selectShowCodeLinesInTab,
    selectBgColorTheme,
    selectEnableAutoSaveConfig,
} = settingsSlice.selectors;

export default settingsSlice.reducer;

