import {Button} from "@mui/material";
import TerminalIcon from "@mui/icons-material/Terminal"
import {useCallback, useEffect, useMemo} from "react";
import WorkerInterval from "../../workers/WorkerInterval";
import MenuBar from "../menu/MenuBar";
import {getNumberRuLocale} from "../utils";
import EngineeringIcon from '@mui/icons-material/Engineering';
import {useDispatch, useSelector} from "react-redux";
import {
    incrementCodeLines,
    incrementTotalBugs,
    initialState as baseInitialState,
    selectCodeLines,
    selectCodeLinesPerClick,
    selectCodeLinesPerSeconds,
    selectModifierCodeLinesPerClick,
    selectModifierCodeLinesPerSeconds,
    selectModifierTotalBugsPerSeconds,
    selectOverallEfficiency,
    selectTotalBugs,
    selectTotalBugsPerSeconds,
    setOverallEfficiency,
    setTotalBugsAndCodeLines
} from "../../store/baseSlice";
import BugReportIcon from '@mui/icons-material/BugReport';
import {addTotalClicks, addTotalGenerateBugs, addTotalGenerateCodeLines} from "../../store/statisticSlice";
import {selectShowCodeLinesInTab} from "../../store/settingsSlice";
import {
    incrementCurrentProgress,
    selectResearch,
    selectResearchInProgress,
    selectResearchProduces
} from "../../store/researchSlice";
import {isEqual, size} from "lodash";
import {IMPROVEMENT_IDS, selectTotalBugsAndCodeLines} from "../../store/improvementsSlice";
import useMessage from "../message/hooks/useMessage";

const interval = 50;

function CounterComponent() {
    const dispatch = useDispatch();

    const codeLines = useSelector(selectCodeLines);
    const showCodeLinesInTab = useSelector(selectShowCodeLinesInTab);

    const codeLinesPerSeconds = useSelector(selectCodeLinesPerSeconds);
    const totalBugsPerSeconds = useSelector(selectTotalBugsPerSeconds);

    const overallEfficiency = useSelector(selectOverallEfficiency);

    const adjustableCodeLinesPerSeconds = useMemo(() => overallEfficiency * codeLinesPerSeconds, [overallEfficiency, codeLinesPerSeconds]);

    const codeLinesStr = getNumberRuLocale(Math.floor(codeLines), true, { minimumFractionDigits: 2 });
    const codeLinesPerSecondsStr = getNumberRuLocale(adjustableCodeLinesPerSeconds.toFixed(1), true);
    const codeLinesPerSecondsSignStr = adjustableCodeLinesPerSeconds > 0 ? '+' : '';

    const hasResearchInProgress = useSelector(selectResearchInProgress, isEqual);
    const enqueueMessage = useMessage();

    useEffect(() => {
        const callback = (dt) => {
            const newInterval = 1000 / dt;
            const codeLinesForInterval = adjustableCodeLinesPerSeconds / newInterval;
            const totalBugsForInterval = totalBugsPerSeconds / newInterval;

            if(size(hasResearchInProgress) > 0) {
                hasResearchInProgress.forEach((key) => dispatch(incrementCurrentProgress({key, value: codeLinesForInterval, enqueueMessage})))
            } else {
                dispatch(incrementCodeLines(codeLinesForInterval));
            }
            dispatch(incrementTotalBugs(totalBugsForInterval));
            dispatch(addTotalGenerateCodeLines(codeLinesForInterval));
            if (totalBugsPerSeconds > 0) {
                dispatch(addTotalGenerateBugs(totalBugsForInterval));
            }
        }

        const workerInterval = new WorkerInterval(callback, interval);
        return () => workerInterval.stop();
    }, [dispatch, totalBugsPerSeconds, adjustableCodeLinesPerSeconds, hasResearchInProgress, enqueueMessage]);

    useEffect(() => {
        if(showCodeLinesInTab) {
            document.title = `${getNumberRuLocale(Math.floor(codeLines))} - Code Clicker`
        } else {
            document.title = `Code Clicker`
        }
    }, [showCodeLinesInTab]);
/*
    useEffect(() => {
        const callback = () => {
            if(showCodeLinesInTab) {
                document.title = `${getNumberRuLocale(Math.floor(codeLines))} - Code Clicker`
            }
        }
        const workerInterval = new WorkerInterval(callback, interval);
        return () => workerInterval.stop();
    }, [showCodeLinesInTab, codeLines]);*/

    return (
        <div className="flex gap-2 items-center text-white/[0.85]">
            <TerminalIcon className="!text-2xl"/>
            <span className="text-lg/[normal]">
                {`${codeLinesStr} (${codeLinesPerSecondsSignStr}${codeLinesPerSecondsStr})`}
            </span>
        </div>
    );
}

function TotalBugsComponent() {
    const dispatch = useDispatch();
    const totalBugs = useSelector(selectTotalBugs);
    const totalBugsPerSeconds = useSelector(selectTotalBugsPerSeconds);

    const totalBugsStr = getNumberRuLocale(Math.floor(totalBugs), true, { minimumFractionDigits: 2 });
    const totalBugsPerSecondsStr = getNumberRuLocale(totalBugsPerSeconds, true);
    const totalBugsPerSecondsSignStr = totalBugsPerSeconds > 0 ? '+' : '';

    useEffect(() => {
        const decreasedProductivity = Math.floor(totalBugs / 200) / 100;
        dispatch(setOverallEfficiency(1 - decreasedProductivity));
    }, [totalBugs]);

    return (
        <div className="flex gap-2 items-center text-white/[0.85]">
            <BugReportIcon className="!text-2xl"/>
            <span className="text-lg/[normal]">
                {`${totalBugsStr} (${totalBugsPerSecondsSignStr}${totalBugsPerSecondsStr})`}
            </span>
        </div>
    );
}

function OverallEfficiencyComponent() {
    const overallEfficiency = useSelector(selectOverallEfficiency);

    const overallEfficiencyStr = (overallEfficiency * 100)?.toFixed(0);

    return (
        <div className="flex gap-2 items-center text-white/[0.85]">
            <EngineeringIcon className="!text-2xl"/>
            <span className="text-lg/[normal]">
                {`${overallEfficiencyStr} %`}
            </span>
        </div>
    );
}

function MainWindow() {
    const dispatch = useDispatch();

    const codeLinesPerClick = useSelector(selectCodeLinesPerClick);
    const totalBugsAndCodeLines = useSelector(selectTotalBugsAndCodeLines, isEqual);
    const researchProduces = useSelector(selectResearchProduces);
    const modifierCodeLinesPerSeconds = useSelector(selectModifierCodeLinesPerSeconds);
    const modifierTotalBugsPerSeconds = useSelector(selectModifierTotalBugsPerSeconds);
    const modifierCodeLinesPerClick = useSelector(selectModifierCodeLinesPerClick);
    const codeLinesPerClickFromEmployees = useSelector((state) => {
        const codeLinesPerClickFromEmployees = state?.base?.codeLinesPerClickFromEmployees;
        const employees = state?.improvements?.[IMPROVEMENT_IDS.EMPLOYEES]?.values;
        const employeesCount = Object.keys(employees)?.reduce((acc, cur) => {
            const count = employees[cur]?.count;
            return acc + count;
        }, 0);
        return codeLinesPerClickFromEmployees * employeesCount;
    });

    const addCodeLines = useCallback(() => {
        const totalCodeLinesPerClicks =  (codeLinesPerClick + codeLinesPerClickFromEmployees) * modifierCodeLinesPerClick;
        dispatch(incrementCodeLines(totalCodeLinesPerClicks));
        dispatch(addTotalGenerateCodeLines(totalCodeLinesPerClicks));
        dispatch(addTotalClicks());
    }, [dispatch, codeLinesPerClick, modifierCodeLinesPerClick, codeLinesPerClickFromEmployees]);

    useEffect(() => {
        const object = {
            codeLinesPerSeconds: (totalBugsAndCodeLines?.codeLinesPerSeconds + researchProduces) * modifierCodeLinesPerSeconds,
            totalBugsPerSeconds: totalBugsAndCodeLines?.totalBugsPerSeconds * modifierTotalBugsPerSeconds,
        };

        dispatch(setTotalBugsAndCodeLines(object));
    }, [totalBugsAndCodeLines, researchProduces, modifierCodeLinesPerSeconds, modifierTotalBugsPerSeconds]);

    return (
        <div className="flex w-full h-full flex-col justify-center gap-4 relative">
            <div className="top-0 absolute gap-4 flex p-4 w-full justify-center">
                <CounterComponent/>
                <TotalBugsComponent/>
                <OverallEfficiencyComponent/>
            </div>
            <div className="flex justify-center">
                <Button
                    variant="contained"
                    className="!p-[40px] !rounded-3xl"
                    color="primary"
                    onClick={addCodeLines}
                >
                    <TerminalIcon className="!text-[160px]/[normal]"/>
                </Button>
            </div>
            <MenuBar/>
        </div>
    );
}

export default MainWindow;