import MainWindow from "./window/MainWindow";
import MatrixEffect from "./background/MatrixEffect";

function Game() {
    return (
        <>
            <MatrixEffect />
            <MainWindow/>
        </>
    );
}

export default Game;