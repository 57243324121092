import Game from "./components/Game";
import {SnackbarProvider} from "notistack";
import {createTheme, ThemeProvider} from "@mui/material";
import {useMemo} from "react";
import {useSelector} from "react-redux";
import {selectBgColorTheme} from "./store/settingsSlice";

const classes = { containerRoot: "items-center" };

function App() {
    const bgColorTheme = useSelector(selectBgColorTheme);

    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    ...(bgColorTheme ? {
                        primary: { main: bgColorTheme },
                    } : {})
                },
            }),[bgColorTheme]);

  return (
    <div className="App">
        <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3} classes={classes}>
                <Game/>
            </SnackbarProvider>
        </ThemeProvider>
    </div>
  );
}

export default App;
