import {createSlice} from "@reduxjs/toolkit";
import {INSTANT_ACTION_IDS} from "../components/utils/constants";

export const RESEARCH_IDS = {
    RESEARCH_1: "RESEARCH_1",
    RESEARCH_2: "RESEARCH_2",
    RESEARCH_3: "RESEARCH_3",
    RESEARCH_4: "RESEARCH_4",
    RESEARCH_5: "RESEARCH_5",
    RESEARCH_6: "RESEARCH_6",
    RESEARCH_7: "RESEARCH_7",
    RESEARCH_8: "RESEARCH_8",
    RESEARCH_9: "RESEARCH_9",
    RESEARCH_10: "RESEARCH_10",
    RESEARCH_11: "RESEARCH_11",
    RESEARCH_12: "RESEARCH_12",
    RESEARCH_13: "RESEARCH_13",
    RESEARCH_14: "RESEARCH_14",
    RESEARCH_15: "RESEARCH_15",
    RESEARCH_16: "RESEARCH_16",
    RESEARCH_17: "RESEARCH_17",
    RESEARCH_18: "RESEARCH_18",
    RESEARCH_19: "RESEARCH_19",
    RESEARCH_20: "RESEARCH_20",
}
/*    [RESEARCH_IDS.RESEARCH_1]: {
    title: "Новый алгоритм сжатия файлов",
        price: 100,
        isComplete: false,
        produces: 1000, //Можно добавить производства
        currenProgress: 0,
        isProgress: false,
},*/
export const initialState = {
    [RESEARCH_IDS.RESEARCH_1]: {
        title: "Основы оптимизации кода",
        price: 100,
        isComplete: false,
        currenProgress: 0,
        isProgress: false,
        instantAction: INSTANT_ACTION_IDS.INCREMENT_MODIFIER_CODE_LINES_PER_SECONDS,
        variableInstantAction: 0.01,
        description: "Увеличивает общую прозводительность на 1%",
    },
    [RESEARCH_IDS.RESEARCH_2]: {
        title: "Автоматизация повторяющихся задач",
        price: 1000,
        isComplete: false,
        currenProgress: 0,
        isProgress: false,
        instantAction: INSTANT_ACTION_IDS.INCREMENT_MODIFIER_CODE_LINES_PER_SECONDS,
        variableInstantAction: 0.02,
        description: "Увеличивает общую прозводительность на 2%",
    },
    [RESEARCH_IDS.RESEARCH_3]: {
        title: "Паттерны проектирования",
        price: 10000,
        isComplete: false,
        currenProgress: 0,
        isProgress: false,
        instantAction: INSTANT_ACTION_IDS.INCREMENT_MODIFIER_CODE_LINES_PER_SECONDS,
        variableInstantAction: 0.03,
        description: "Увеличивает общую прозводительность на 3%",
    },
    [RESEARCH_IDS.RESEARCH_4]: {
        title: "Анализ и рефакторинг",
        price: 100000,
        isComplete: false,
        currenProgress: 0,
        isProgress: false,
        instantAction: INSTANT_ACTION_IDS.INCREMENT_MODIFIER_CODE_LINES_PER_SECONDS,
        variableInstantAction: 0.04,
        description: "Увеличивает общую прозводительность на 4%",
    },
    [RESEARCH_IDS.RESEARCH_5]: {
        title: "Инструменты для разработчиков",
        price: 1000000,
        isComplete: false,
        currenProgress: 0,
        isProgress: false,
        instantAction: INSTANT_ACTION_IDS.INCREMENT_MODIFIER_CODE_LINES_PER_SECONDS,
        variableInstantAction: 0.05,
        description: "Увеличивает общую прозводительность на 5%",
    },
    [RESEARCH_IDS.RESEARCH_6]: {
        title: "Кодовые генераторы",
        price: 10000000,
        isComplete: false,
        currenProgress: 0,
        isProgress: false,
        instantAction: INSTANT_ACTION_IDS.INCREMENT_MODIFIER_CODE_LINES_PER_SECONDS,
        variableInstantAction: 0.06,
        description: "Увеличивает общую прозводительность на 6%",
    },
    [RESEARCH_IDS.RESEARCH_7]: {
        title: "Внедрение CI/CD",
        price: 100000000,
        isComplete: false,
        currenProgress: 0,
        isProgress: false,
        instantAction: INSTANT_ACTION_IDS.INCREMENT_MODIFIER_CODE_LINES_PER_SECONDS,
        variableInstantAction: 0.07,
        description: "Увеличивает общую прозводительность на 7%",
    },
    [RESEARCH_IDS.RESEARCH_8]: {
        title: "Тестирование и отладка",
        price: 1000000000,
        isComplete: false,
        currenProgress: 0,
        isProgress: false,
        instantAction: INSTANT_ACTION_IDS.INCREMENT_MODIFIER_CODE_LINES_PER_SECONDS,
        variableInstantAction: 0.08,
        description: "Увеличивает общую прозводительность на 8%",
    },
    [RESEARCH_IDS.RESEARCH_9]: {
        title: "Профилирование и оптимизация",
        price: 10000000000,
        isComplete: false,
        currenProgress: 0,
        isProgress: false,
        instantAction: INSTANT_ACTION_IDS.INCREMENT_MODIFIER_CODE_LINES_PER_SECONDS,
        variableInstantAction: 0.09,
        description: "Увеличивает общую прозводительность на 9%",
    },
    [RESEARCH_IDS.RESEARCH_10]: {
        title: "Работа с большими данными",
        price: 100000000000,
        isComplete: false,
        currenProgress: 0,
        isProgress: false,
        instantAction: INSTANT_ACTION_IDS.INCREMENT_MODIFIER_CODE_LINES_PER_SECONDS,
        variableInstantAction: 0.10,
        description: "Увеличивает общую прозводительность на 10%",
    },
    [RESEARCH_IDS.RESEARCH_11]: {
        title: "Управление версиями",
        price: 1000000000000,
        isComplete: false,
        currenProgress: 0,
        isProgress: false,
        instantAction: INSTANT_ACTION_IDS.INCREMENT_MODIFIER_CODE_LINES_PER_SECONDS,
        variableInstantAction: 0.11,
        description: "Увеличивает общую прозводительность на 11%",
    },
    [RESEARCH_IDS.RESEARCH_12]: {
        title: "Модульное тестирование",
        price: 10000000000000,
        isComplete: false,
        currenProgress: 0,
        isProgress: false,
        instantAction: INSTANT_ACTION_IDS.INCREMENT_MODIFIER_CODE_LINES_PER_SECONDS,
        variableInstantAction: 0.12,
        description: "Увеличивает общую прозводительность на 12%",
    },
    [RESEARCH_IDS.RESEARCH_13]: {
        title: "Анализ производительности",
        price: 100000000000000,
        isComplete: false,
        currenProgress: 0,
        isProgress: false,
        instantAction: INSTANT_ACTION_IDS.INCREMENT_MODIFIER_CODE_LINES_PER_SECONDS,
        variableInstantAction: 0.13,
        description: "Увеличивает общую прозводительность на 13%",
    },
    [RESEARCH_IDS.RESEARCH_14]: {
        title: "Мультиязыковая разработка",
        price: 1000000000000000,
        isComplete: false,
        currenProgress: 0,
        isProgress: false,
        instantAction: INSTANT_ACTION_IDS.INCREMENT_MODIFIER_CODE_LINES_PER_SECONDS,
        variableInstantAction: 0.14,
        description: "Увеличивает общую прозводительность на 14%",
    },
    [RESEARCH_IDS.RESEARCH_15]: {
        title: "Интеграция с облачными сервисами",
        price: 10000000000000000,
        isComplete: false,
        currenProgress: 0,
        isProgress: false,
        instantAction: INSTANT_ACTION_IDS.INCREMENT_MODIFIER_CODE_LINES_PER_SECONDS,
        variableInstantAction: 0.15,
        description: "Увеличивает общую прозводительность на 15%",
    },
    [RESEARCH_IDS.RESEARCH_16]: {
        title: "Библиотеки и фреймворки",
        price: 100000000000000000,
        isComplete: false,
        currenProgress: 0,
        isProgress: false,
        instantAction: INSTANT_ACTION_IDS.INCREMENT_MODIFIER_CODE_LINES_PER_SECONDS,
        variableInstantAction: 0.16,
        description: "Увеличивает общую прозводительность на 16%",
    },
    [RESEARCH_IDS.RESEARCH_17]: {
        title: "Документирование кода",
        price: 1000000000000000000,
        isComplete: false,
        currenProgress: 0,
        isProgress: false,
        instantAction: INSTANT_ACTION_IDS.INCREMENT_MODIFIER_CODE_LINES_PER_SECONDS,
        variableInstantAction: 0.17,
        description: "Увеличивает общую прозводительность на 17%",
    },
    [RESEARCH_IDS.RESEARCH_18]: {
        title: "Кодовые ревью",
        price: 10000000000000000000,
        isComplete: false,
        currenProgress: 0,
        isProgress: false,
        instantAction: INSTANT_ACTION_IDS.INCREMENT_MODIFIER_CODE_LINES_PER_SECONDS,
        variableInstantAction: 0.18,
        description: "Увеличивает общую прозводительность на 18%",
    },
    [RESEARCH_IDS.RESEARCH_19]: {
        title: "Кроссплатформенная разработка",
        price: 100000000000000000000,
        isComplete: false,
        currenProgress: 0,
        isProgress: false,
        instantAction: INSTANT_ACTION_IDS.INCREMENT_MODIFIER_CODE_LINES_PER_SECONDS,
        variableInstantAction: 0.19,
        description: "Увеличивает общую прозводительность на 19%",
    },
    [RESEARCH_IDS.RESEARCH_20]: {
        title: "Интеграция AI для написания кода",
        price: 1000000000000000000000,
        isComplete: false,
        currenProgress: 0,
        isProgress: false,
        instantAction: INSTANT_ACTION_IDS.INCREMENT_MODIFIER_CODE_LINES_PER_SECONDS,
        variableInstantAction: 0.20,
        description: "Увеличивает общую прозводительность на 20%",
    },
};

const reducers = {
    startProgress: (state, action) => {
        state[action?.payload].isProgress = true;
    },
    setResearchComplete: (state, action) => {
        state[action?.payload?.key].isComplete = action?.payload?.value;
    },
    incrementCurrentProgress: (state, action) => {
        const enqueueMessage = action?.payload?.enqueueMessage;
        state[action?.payload?.key].currenProgress += action?.payload?.value;
        if(state[action?.payload?.key].currenProgress >= state[action?.payload?.key].price) {
            state[action?.payload?.key].isProgress = false;
            enqueueMessage('Исследование завершено. Заберите награду!')
        }
    }
};

const selectors = {
    selectResearchKeys: (state) => Object.keys(state),
    selectResearch: (state) => state,
    selectResearchInProgress: (state) => Object.keys(state).filter((key) => state[key]?.isProgress),
    selectResearchProduces: (state) => Object.keys(state).reduce((acc, cur) => {
        const produces = state[cur]?.isComplete ? state[cur]?.produces ?? 0 : 0;
        return acc + produces;
    }, 0),
    selectResearchInProgressComplete: (state) => Object.keys(state).filter((key) => state[key]?.currenProgress >= state[key]?.price),
};

const researchSlice = createSlice({
    name: "research",
    initialState,
    reducers,
    selectors,
});

export const {
    startProgress,
    setResearchComplete,
    incrementCurrentProgress,
} = researchSlice.actions;

export const {
    selectResearchKeys,
    selectResearch,
    selectResearchInProgress,
    selectResearchProduces,
    selectResearchInProgressComplete,
} = researchSlice.selectors;

export default researchSlice.reducer;

