import {createSlice} from "@reduxjs/toolkit";
import {getFunctionNewPrice, isFunction} from "../components/utils";
import {initialState as baseInitialState} from "./baseSlice";
import {INSTANT_ACTION_IDS, PRICE_INCREASE_IDS} from "../components/utils/constants";

export const IMPROVEMENT_IDS = {
    EMPLOYEES: "EMPLOYEES",
    EQUIPMENT: "EQUIPMENT",
};

export const EMPLOYEES_IDS = {
    TRAINEE: "TRAINEE",
    FREELANCER: "FREELANCER",
    NOVICE_TESTER: "NOVICE_TESTER",
    FRONTEND_DEVELOPER: "FRONTEND_DEVELOPER",
    BACKEND_DEVELOPER: "BACKEND_DEVELOPER",
    QA_ENGINEER: "QA_ENGINEER",
    DEVOPS_ENGINEER: "DEVOPS_ENGINEER",
    MOBILE_DEVELOPER: "MOBILE_DEVELOPER",
    TEST_AUTOMATION_ENGINEER: "TEST_AUTOMATION_ENGINEER",
    DATABASE_DEVELOPER: "DATABASE_DEVELOPER",
    FULL_STACK_DEVELOPER: "FULL_STACK_DEVELOPER",
    PERFORMANCE_TEST_ENGINEER: "PERFORMANCE_TEST_ENGINEER",
    CLOUD_ENGINEER: "CLOUD_ENGINEER",
    MACHINE_LEARNING_ENGINEER: "MACHINE_LEARNING_ENGINEER",
    TEST_ANALYST: "TEST_ANALYST",
    BLOCKCHAIN_DEVELOPER: "BLOCKCHAIN_DEVELOPER",
    IOT_DEVELOPER: "IOT_DEVELOPER",
}

export const EQUIPMENT_IDS = {
    IMPROVED_MOUSE: "IMPROVED_MOUSE",
    IMPROVED_FOR_TRAINEE: "IMPROVED_FOR_TRAINEE",
    IMPROVED_FOR_FREELANCER: "IMPROVED_FOR_FREELANCER",
    IMPROVED_FOR_NOVICE_TESTER: "IMPROVED_FOR_NOVICE_TESTER",
    IMPROVED_FOR_FRONTEND_DEVELOPER: "IMPROVED_FOR_FRONTEND_DEVELOPER",
    IMPROVED_FOR_BACKEND_DEVELOPER: "IMPROVED_FOR_BACKEND_DEVELOPER",
    IMPROVED_FOR_QA_ENGINEER: "IMPROVED_FOR_QA_ENGINEER",
    IMPROVED_FOR_DEVOPS_ENGINEER: "IMPROVED_FOR_DEVOPS_ENGINEER",
    IMPROVED_FOR_MOBILE_DEVELOPER: "IMPROVED_FOR_MOBILE_DEVELOPER",
    IMPROVED_FOR_TEST_AUTOMATION_ENGINEER: "IMPROVED_FOR_TEST_AUTOMATION_ENGINEER",
    IMPROVED_FOR_DATABASE_DEVELOPER: "IMPROVED_FOR_DATABASE_DEVELOPER",
    IMPROVED_FOR_FULL_STACK_DEVELOPER: "IMPROVED_FOR_FULL_STACK_DEVELOPER",
    IMPROVED_FOR_PERFORMANCE_TEST_ENGINEER: "IMPROVED_FOR_PERFORMANCE_TEST_ENGINEER",
    IMPROVED_FOR_CLOUD_ENGINEER: "IMPROVED_FOR_CLOUD_ENGINEER",
    IMPROVED_FOR_MACHINE_LEARNING_ENGINEER: "IMPROVED_FOR_MACHINE_LEARNING_ENGINEER",
    IMPROVED_FOR_TEST_ANALYST: "IMPROVED_FOR_TEST_ANALYST",
    IMPROVED_FOR_BLOCKCHAIN_DEVELOPER: "IMPROVED_FOR_BLOCKCHAIN_DEVELOPER",
    IMPROVED_FOR_IOT_DEVELOPER: "IMPROVED_FOR_IOT_DEVELOPER",
}

export const initialState = {
    [IMPROVEMENT_IDS.EMPLOYEES]: {
        title: "Найм сотрудников",
        values: {
            [EMPLOYEES_IDS.TRAINEE]: {
                title: "Стажер",
                count: 0,
                price: 15,
                basePrice: 15,
                produces: 0.1,
                modifierProduces: 1,
                modifierBugs: 1,
                bugs: 0.2,
                typePriceIncrease: "PERCENT_INCREASE_15",
            },
            [EMPLOYEES_IDS.FREELANCER]: {
                title: "Фрилансер",
                count: 0,
                price: 100,
                basePrice: 100,
                produces: 1,
                modifierProduces: 1,
                modifierBugs: 1,
                bugs: 2,
                typePriceIncrease: "PERCENT_INCREASE_15",
            },
            [EMPLOYEES_IDS.NOVICE_TESTER]: {
                title: "Начинающий тестировщик",
                count: 0,
                price: 1100,
                basePrice: 1100,
                modifierBugs: 1,
                bugs: -8,
                typePriceIncrease: "PERCENT_INCREASE_15",
            },
            [EMPLOYEES_IDS.FRONTEND_DEVELOPER]: {
                title: "Frontend-разработчик",
                count: 0,
                price: 12000,
                basePrice: 12000,
                produces: 47,
                modifierProduces: 1,
                modifierBugs: 1,
                bugs: 94,
                typePriceIncrease: "PERCENT_INCREASE_15",
            },
            [EMPLOYEES_IDS.BACKEND_DEVELOPER]: {
                title: "Backend-разработчик",
                count: 0,
                price: 130000,
                basePrice: 130000,
                produces: 260,
                modifierProduces: 1,
                modifierBugs: 1,
                bugs: 520,
                typePriceIncrease: "PERCENT_INCREASE_15",
            },
            [EMPLOYEES_IDS.QA_ENGINEER]: {
                title: "QA-инженер",
                count: 0,
                price: 1400000,
                basePrice: 1400000,
                modifierBugs: 1,
                bugs: -1400,
                typePriceIncrease: "PERCENT_INCREASE_15",
            },
            [EMPLOYEES_IDS.DEVOPS_ENGINEER]: {
                title: "DevOps-инженер",
                count: 0,
                price: 20000000,
                basePrice: 20000000,
                produces: 7800,
                modifierProduces: 1,
                modifierBugs: 1,
                bugs: 15600,
                typePriceIncrease: "PERCENT_INCREASE_15",
            },
            [EMPLOYEES_IDS.MOBILE_DEVELOPER]: {
                title: "Мобильный разработчик",
                count: 0,
                price: 330000000,
                basePrice: 330000000,
                produces: 44000,
                modifierProduces: 1,
                modifierBugs: 1,
                bugs: 88000,
                typePriceIncrease: "PERCENT_INCREASE_15",
            },
            [EMPLOYEES_IDS.TEST_AUTOMATION_ENGINEER]: {
                title: "Инженер по автоматизации тестирования",
                count: 0,
                price: 5100000000,
                basePrice: 5100000000,
                produces: 130000,
                modifierProduces: 1,
                modifierBugs: 1,
                bugs: -260000,
                typePriceIncrease: "PERCENT_INCREASE_15",
            },
            [EMPLOYEES_IDS.DATABASE_DEVELOPER]: {
                title: "Разработчик баз данных",
                count: 0,
                price: 75000000000,
                basePrice: 75000000000,
                produces: 1600000,
                modifierProduces: 1,
                modifierBugs: 1,
                bugs: 3200000,
                typePriceIncrease: "PERCENT_INCREASE_15",
            },
            [EMPLOYEES_IDS.FULL_STACK_DEVELOPER]: {
                title: "Full-stack разработчик",
                count: 0,
                price: 1000000000000,
                basePrice: 1000000000000,
                produces: 10000000,
                modifierProduces: 1,
                modifierBugs: 1,
                bugs: 20000000,
                typePriceIncrease: "PERCENT_INCREASE_15",
            },
            [EMPLOYEES_IDS.PERFORMANCE_TEST_ENGINEER]: {
                title: "Инженер по тестированию производительности",
                count: 0,
                price: 14000000000000,
                basePrice: 14000000000000,
                modifierBugs: 1,
                bugs: -65000000,
                typePriceIncrease: "PERCENT_INCREASE_15",
            },
            [EMPLOYEES_IDS.CLOUD_ENGINEER]: {
                title: "Облачный инженер",
                count: 0,
                price: 170000000000000,
                basePrice: 170000000000000,
                produces: 430000000,
                modifierProduces: 1,
                modifierBugs: 1,
                bugs: 860000000,
                typePriceIncrease: "PERCENT_INCREASE_15",
            },
            [EMPLOYEES_IDS.MACHINE_LEARNING_ENGINEER]: {
                title: "Инженер по машинному обучению",
                count: 0,
                price: 2100000000000000,
                basePrice: 2100000000000000,
                produces: 2900000000,
                modifierProduces: 1,
                modifierBugs: 1,
                bugs: 5800000000,
                typePriceIncrease: "PERCENT_INCREASE_15",
            },
            [EMPLOYEES_IDS.TEST_ANALYST]: {
                title: "Аналитик качества",
                count: 0,
                price: 26000000000000000,
                basePrice: 26000000000000000,
                modifierBugs: 1,
                bugs: -21000000000,
                typePriceIncrease: "PERCENT_INCREASE_15",
            },
            [EMPLOYEES_IDS.BLOCKCHAIN_DEVELOPER]: {
                title: "Разработчик блокчейн",
                count: 0,
                price: 310000000000000000,
                basePrice: 310000000000000000,
                produces: 150000000000,
                modifierProduces: 1,
                modifierBugs: 1,
                bugs: 300000000000,
                typePriceIncrease: "PERCENT_INCREASE_15",
            },
            [EMPLOYEES_IDS.IOT_DEVELOPER]: {
                title: "Разработчик IoT",
                count: 0,
                price: 71000000000000000000,
                basePrice: 71000000000000000000,
                produces: 1100000000000,
                modifierProduces: 1,
                modifierBugs: 1,
                bugs: 2200000000000,
                typePriceIncrease: "PERCENT_INCREASE_15",
            },
        },
    },
    [IMPROVEMENT_IDS.EQUIPMENT]: {
        title: "Улучшение оборудования",
        values: {
            [EQUIPMENT_IDS.IMPROVED_MOUSE]: {
                title: "Стандартная офисная мышь",
                count: 0,
                price: 100,
                basePrice: 100,
                typePriceIncrease: PRICE_INCREASE_IDS.EXPONENTIAL_INCREASE_C_2,
                maxCount: 64,
                instantAction: INSTANT_ACTION_IDS.DOUBLE_CLICKS,
                tooltip: "Увеличивает эффективность мыши в 2 раза",
            },
            [EQUIPMENT_IDS.IMPROVED_FOR_TRAINEE]: {
                title: "Современный ноутбук",
                count: 0,
                price: 100,
                basePrice: 100,
                maxCount: 12,
                typePriceIncrease: PRICE_INCREASE_IDS.EXPONENTIAL_INCREASE,
                instantAction: INSTANT_ACTION_IDS.EMPLOYEES_INCREASE_PRODUCES_100,
                variableInstantAction: [EMPLOYEES_IDS.TRAINEE],
                tooltip: "Увеличивает в 2 раза количество строчек производимого стажерами",
            },
            [EQUIPMENT_IDS.IMPROVED_FOR_FREELANCER]: {
                title: "Высокоскоростной интернет",
                count: 0,
                price: 1000,
                basePrice: 1000,
                maxCount: 12,
                typePriceIncrease: PRICE_INCREASE_IDS.EXPONENTIAL_INCREASE,
                instantAction: INSTANT_ACTION_IDS.EMPLOYEES_INCREASE_PRODUCES_100,
                variableInstantAction: [EMPLOYEES_IDS.FREELANCER],
                tooltip: "Увеличивает в 2 раза количество строчек производимого фрилансерами",
            },
            [EQUIPMENT_IDS.IMPROVED_FOR_NOVICE_TESTER]: {
                title: "Профессиональный софт для тестирования",
                count: 0,
                price: 11000,
                basePrice: 11000,
                maxCount: 12,
                typePriceIncrease: PRICE_INCREASE_IDS.EXPONENTIAL_INCREASE,
                instantAction: INSTANT_ACTION_IDS.EMPLOYEES_INCREASE_BUGS_100,
                variableInstantAction: [EMPLOYEES_IDS.NOVICE_TESTER],
                tooltip: "Увеличивает в 2 раза количество исправляемых багов начинающими тестировщиками",
            },
            [EQUIPMENT_IDS.IMPROVED_FOR_FRONTEND_DEVELOPER]: {
                title: "Монитор с высоким разрешением",
                count: 0,
                price: 120000,
                basePrice: 120000,
                maxCount: 12,
                typePriceIncrease: PRICE_INCREASE_IDS.EXPONENTIAL_INCREASE,
                instantAction: INSTANT_ACTION_IDS.EMPLOYEES_INCREASE_PRODUCES_100,
                variableInstantAction: [EMPLOYEES_IDS.FRONTEND_DEVELOPER],
                tooltip: "Увеличивает в 2 раза количество строчек производимого frontend-разработчиками",
            },
            [EQUIPMENT_IDS.IMPROVED_FOR_BACKEND_DEVELOPER]: {
                title: "Высокопроизводительный сервер",
                count: 0,
                price: 1300000,
                basePrice: 1300000,
                maxCount: 12,
                typePriceIncrease: PRICE_INCREASE_IDS.EXPONENTIAL_INCREASE,
                instantAction: INSTANT_ACTION_IDS.EMPLOYEES_INCREASE_PRODUCES_100,
                variableInstantAction: [EMPLOYEES_IDS.BACKEND_DEVELOPER],
                tooltip: "Увеличивает в 2 раза количество строчек производимого backend-разработчиками",
            },
            [EQUIPMENT_IDS.IMPROVED_FOR_QA_ENGINEER]: {
                title: "Система управления тестированием",
                count: 0,
                price: 14000000,
                basePrice: 14000000,
                maxCount: 12,
                typePriceIncrease: PRICE_INCREASE_IDS.EXPONENTIAL_INCREASE,
                instantAction: INSTANT_ACTION_IDS.EMPLOYEES_INCREASE_BUGS_100,
                variableInstantAction: [EMPLOYEES_IDS.QA_ENGINEER],
                tooltip: "Увеличивает в 2 раза количество исправляемых багов qa-инженерами",
            },
            [EQUIPMENT_IDS.IMPROVED_FOR_DEVOPS_ENGINEER]: {
                title: "Система для управления контейнерами",
                count: 0,
                price: 200000000,
                basePrice: 200000000,
                maxCount: 12,
                typePriceIncrease: PRICE_INCREASE_IDS.EXPONENTIAL_INCREASE,
                instantAction: INSTANT_ACTION_IDS.EMPLOYEES_INCREASE_PRODUCES_100,
                variableInstantAction: [EMPLOYEES_IDS.DEVOPS_ENGINEER],
                tooltip: "Увеличивает в 2 раза количество строчек производимого devops-инженерами",
            },
            [EQUIPMENT_IDS.IMPROVED_FOR_MOBILE_DEVELOPER]: {
                title: "Комплексная платформа для мобильной разработки",
                count: 0,
                price: 3300000000,
                basePrice: 3300000000,
                maxCount: 12,
                typePriceIncrease: PRICE_INCREASE_IDS.EXPONENTIAL_INCREASE,
                instantAction: INSTANT_ACTION_IDS.EMPLOYEES_INCREASE_PRODUCES_100,
                variableInstantAction: [EMPLOYEES_IDS.MOBILE_DEVELOPER],
                tooltip: "Увеличивает в 2 раза количество строчек производимого мобильными разработчиками",
            },
            [EQUIPMENT_IDS.IMPROVED_FOR_TEST_AUTOMATION_ENGINEER]: {
                title: "Платформа для автоматизации тестов",
                count: 0,
                price: 51000000000,
                basePrice: 51000000000,
                maxCount: 12,
                typePriceIncrease: PRICE_INCREASE_IDS.EXPONENTIAL_INCREASE,
                instantAction: INSTANT_ACTION_IDS.EMPLOYEES_INCREASE_BUGS_100,
                variableInstantAction: [EMPLOYEES_IDS.TEST_AUTOMATION_ENGINEER],
                tooltip: "Увеличивает в 2 раза количество исправляемых багов инженерами по автоматизации тестирования",
            },
            [EQUIPMENT_IDS.IMPROVED_FOR_DATABASE_DEVELOPER]: {
                title: "Инструменты для резервного копирования и восстановления данных",
                count: 0,
                price: 750000000000,
                basePrice: 750000000000,
                maxCount: 12,
                typePriceIncrease: PRICE_INCREASE_IDS.EXPONENTIAL_INCREASE,
                instantAction: INSTANT_ACTION_IDS.EMPLOYEES_INCREASE_PRODUCES_100,
                variableInstantAction: [EMPLOYEES_IDS.DATABASE_DEVELOPER],
                tooltip: "Увеличивает в 2 раза количество строчек производимого разработчиками баз данных",
            },
            [EQUIPMENT_IDS.IMPROVED_FOR_FULL_STACK_DEVELOPER]: {
                title: "Мощная рабочая станция",
                count: 0,
                price: 10000000000000,
                basePrice: 10000000000000,
                maxCount: 12,
                typePriceIncrease: PRICE_INCREASE_IDS.EXPONENTIAL_INCREASE,
                instantAction: INSTANT_ACTION_IDS.EMPLOYEES_INCREASE_PRODUCES_100,
                variableInstantAction: [EMPLOYEES_IDS.FULL_STACK_DEVELOPER],
                tooltip: "Увеличивает в 2 раза количество строчек производимого full-stack разработчиками",
            },
            [EQUIPMENT_IDS.IMPROVED_FOR_PERFORMANCE_TEST_ENGINEER]: {
                title: "Инструменты для нагрузочного тестирования",
                count: 0,
                price: 140000000000000,
                basePrice: 140000000000000,
                maxCount: 12,
                typePriceIncrease: PRICE_INCREASE_IDS.EXPONENTIAL_INCREASE,
                instantAction: INSTANT_ACTION_IDS.EMPLOYEES_INCREASE_BUGS_100,
                variableInstantAction: [EMPLOYEES_IDS.PERFORMANCE_TEST_ENGINEER],
                tooltip: "Увеличивает в 2 раза количество исправляемых багов инженерами по тестированию производительности",
            },
            [EQUIPMENT_IDS.IMPROVED_FOR_CLOUD_ENGINEER]: {
                title: "Платформа для управления облачными ресурсами",
                count: 0,
                price: 1700000000000000,
                basePrice: 1700000000000000,
                maxCount: 12,
                typePriceIncrease: PRICE_INCREASE_IDS.EXPONENTIAL_INCREASE,
                instantAction: INSTANT_ACTION_IDS.EMPLOYEES_INCREASE_PRODUCES_100,
                variableInstantAction: [EMPLOYEES_IDS.CLOUD_ENGINEER],
                tooltip: "Увеличивает в 2 раза количество строчек производимого облачными инженерами",
            },
            [EQUIPMENT_IDS.IMPROVED_FOR_MACHINE_LEARNING_ENGINEER]: {
                title: "Сервер с графическими процессорами",
                count: 0,
                price: 21000000000000000,
                basePrice: 21000000000000000,
                maxCount: 12,
                typePriceIncrease: PRICE_INCREASE_IDS.EXPONENTIAL_INCREASE,
                instantAction: INSTANT_ACTION_IDS.EMPLOYEES_INCREASE_PRODUCES_100,
                variableInstantAction: [EMPLOYEES_IDS.MACHINE_LEARNING_ENGINEER],
                tooltip: "Увеличивает в 2 раза количество строчек производимого инженерами по машинному обучению",
            },
            [EQUIPMENT_IDS.IMPROVED_FOR_TEST_ANALYST]: {
                title: "Инструменты для анализа качества кода",
                count: 0,
                price: 260000000000000000,
                basePrice: 260000000000000000,
                maxCount: 12,
                typePriceIncrease: PRICE_INCREASE_IDS.EXPONENTIAL_INCREASE,
                instantAction: INSTANT_ACTION_IDS.EMPLOYEES_INCREASE_BUGS_100,
                variableInstantAction: [EMPLOYEES_IDS.TEST_ANALYST],
                tooltip: "Увеличивает в 2 раза количество исправляемых багов аналитиками качества",
            },
            [EQUIPMENT_IDS.IMPROVED_FOR_BLOCKCHAIN_DEVELOPER]: {
                title: "Полные ноды блокчейн-сети",
                count: 0,
                price: 3100000000000000000,
                basePrice: 3100000000000000000,
                maxCount: 12,
                typePriceIncrease: PRICE_INCREASE_IDS.EXPONENTIAL_INCREASE,
                instantAction: INSTANT_ACTION_IDS.EMPLOYEES_INCREASE_PRODUCES_100,
                variableInstantAction: [EMPLOYEES_IDS.BLOCKCHAIN_DEVELOPER],
                tooltip: "Увеличивает в 2 раза количество строчек производимого разработчиками блокчейн",
            },
            [EQUIPMENT_IDS.IMPROVED_FOR_IOT_DEVELOPER]: {
                title: "Платформа для разработки IoT",
                count: 0,
                price: 710000000000000000000,
                basePrice: 710000000000000000000,
                maxCount: 12,
                typePriceIncrease: PRICE_INCREASE_IDS.EXPONENTIAL_INCREASE,
                instantAction: INSTANT_ACTION_IDS.EMPLOYEES_INCREASE_PRODUCES_100,
                variableInstantAction: [EMPLOYEES_IDS.IOT_DEVELOPER],
                tooltip: "Увеличивает в 2 раза количество строчек производимого разработчиками IoT",
            },
        },
    }
};

const reducers = {
    updatePriceAndCountImprovement: (state, action) => {
        const {idImprovements, id} = action?.payload;
        const improvement = state?.[idImprovements]?.values?.[id];
        const setNewPrice = getFunctionNewPrice(improvement?.typePriceIncrease);

        improvement.count++;

        if (isFunction(setNewPrice) && (!improvement?.maxCount || improvement?.count < improvement?.maxCount)) {
            improvement.price = setNewPrice(improvement);
        }
    },
    updatePercentProducesForEmployees: (state, action) => {
        const employees = state?.[IMPROVEMENT_IDS.EMPLOYEES].values;
        const searchEmployees = action?.payload?.employees;
        const percent = action?.payload?.percent;

        searchEmployees.forEach((item) => {
            const employee = employees[item];
            employee.modifierProduces += percent;
        });
    },
    multipleProducesForEmployees: (state, action) => {
        const employees = state?.[IMPROVEMENT_IDS.EMPLOYEES].values;
        const searchEmployees = action?.payload?.employees;
        const coefficient = action?.payload?.coefficient;

        searchEmployees.forEach((item) => {
            const employee = employees[item];
            employee.produces *= coefficient;
        });
    },
    updatePercentBugsForEmployees: (state, action) => {
        const employees = state?.[IMPROVEMENT_IDS.EMPLOYEES].values;
        const searchEmployees = action?.payload?.employees;
        const percent = action?.payload?.percent;

        searchEmployees.forEach((item) => {
            const employee = employees[item];
            employee.modifierBugs += percent;
        });
    },
    multipleBugsForEmployees: (state, action) => {
        const employees = state?.[IMPROVEMENT_IDS.EMPLOYEES].values;
        const searchEmployees = action?.payload?.employees;
        const coefficient = action?.payload?.coefficient;

        searchEmployees.forEach((item) => {
            const employee = employees[item];
            employee.bugs *= coefficient;
        });
    },
};

const selectors = {
    selectImprovements: (state) => state,
    selectEmployees: (state) => state[IMPROVEMENT_IDS.EMPLOYEES],
    selectEquipment: (state) => state[IMPROVEMENT_IDS.EQUIPMENT],
    selectTotalBugsAndCodeLines: (state) =>
        Object.keys(state).reduce((acc, cur) => {
            const improvementValues = state?.[cur]?.values;
            const {improvementValuesProduces, improvementValuesBugs} = Object.keys(improvementValues).reduce((acc, cur) => {
                const produces = improvementValues?.[cur]?.produces ?? 0;
                const bugs = improvementValues?.[cur]?.bugs ?? 0;
                const count = improvementValues?.[cur]?.count ?? 0;
                const modifierProduces = improvementValues?.[cur]?.modifierProduces ?? 1;
                const modifierBugs = improvementValues?.[cur]?.modifierBugs ?? 1;

                return {
                    improvementValuesProduces: acc?.improvementValuesProduces + count * produces * modifierProduces,
                    improvementValuesBugs: acc?.improvementValuesBugs + count * bugs * modifierBugs,
                }
            }, {improvementValuesProduces: 0, improvementValuesBugs: 0});

            return {
                codeLinesPerSeconds: acc?.codeLinesPerSeconds + improvementValuesProduces,
                totalBugsPerSeconds: acc?.totalBugsPerSeconds + improvementValuesBugs,
            };
        }, {codeLinesPerSeconds: baseInitialState.codeLinesPerSeconds, totalBugsPerSeconds: baseInitialState.totalBugsPerSeconds}),
};

const improvementsSlice = createSlice({
    name: "improvements",
    initialState,
    reducers,
    selectors,
});

export const {
    updatePriceAndCountImprovement,
    updatePercentProducesForEmployees,
    updatePercentBugsForEmployees,
    multipleProducesForEmployees,
    multipleBugsForEmployees,
} = improvementsSlice.actions;

export const {
    selectImprovements,
    selectEmployees,
    selectEquipment,
    selectTotalBugsAndCodeLines,
} = improvementsSlice.selectors;

export default improvementsSlice.reducer;



/*
    DESIGNER: "DESIGNER",
    JUNIOR_DEVELOPER: "JUNIOR_DEVELOPER",
    MIDDLE_DEVELOPER: "MIDDLE_DEVELOPER",
    SENIOR_DEVELOPER: "SENIOR_DEVELOPER",*/

/*    SECURITY_TESTER: "SECURITY_TESTER",
    BIG_DATA_ENGINEER: "BIG_DATA_ENGINEER",
    ROBOTICS_DEVELOPER: "ROBOTICS_DEVELOPER",*/

/*            [EMPLOYEES_IDS.DESIGNER]: {
                title: "дизайнер",
                count: 0,
                price: 15,
                produces: 0.1,
                modifierProduces: 1,
                modifierBugs: 1,
                bugs: 0.2,
                typePriceIncrease: "PERCENT_INCREASE_15",
                icon: "brush",
            },
            [EMPLOYEES_IDS.JUNIOR_DEVELOPER]: {
                title: "младший разработчик",
                count: 0,
                price: 100,
                produces: 1,
                modifierProduces: 1,
                modifierBugs: 1,
                bugs: 0.5,
                typePriceIncrease: "PERCENT_INCREASE_15",
                icon: "html",
            },
            [EMPLOYEES_IDS.MIDDLE_DEVELOPER]: {
                title: "средний разработчик",
                count: 0,
                price: 1100,
                produces: 8,
                modifierProduces: 1,
                modifierBugs: 1,
                bugs: 0.3,
                typePriceIncrease: "PERCENT_INCREASE_15",
                icon: "javascript",
            },
            [EMPLOYEES_IDS.SENIOR_DEVELOPER]: {
                title: "ведущий разработчик",
                count: 0,
                price: 12000,
                produces: 47,
                modifierProduces: 1,
                modifierBugs: 1,
                bugs: 0.1,
                typePriceIncrease: "PERCENT_INCREASE_15",
                icon: "php",
            },
            [EMPLOYEES_IDS.QA_ENGINEER]: {
                title: "тестировщик",
                count: 0,
                price: 500,
                produces: 0,
                modifierProduces: 1,
                modifierBugs: 1,
                bugs: -1,
                typePriceIncrease: "PERCENT_INCREASE_15",
                icon: "bug_report",
            },*/

/*    IMPROVED_MOUSE: "IMPROVED_MOUSE",
    IMPROVED_KEYBOARD: "IMPROVED_KEYBOARD",
    IMPROVED_MONITOR: "IMPROVED_MONITOR",*/

/*            [EQUIPMENT_IDS.IMPROVED_MOUSE]: {
                title: "улучшенная мышь",
                count: 0,
                price: 100,
                typePriceIncrease: "PERCENT_INCREASE_500",
                icon: "mouse",
                maxCount: 3,
                instantAction: "DOUBLE_CLICKS",
                tooltip: "Увеличивает эффективность мыши в 2 раза",
            },
            [EQUIPMENT_IDS.IMPROVED_KEYBOARD]: {
                title: "улучшенная клавиатура",
                count: 0,
                price: 1,
                icon: "keyboard",
                maxCount: 1,
                instantAction: "INCREASE_PRODUCES_10_JMS",
                tooltip: "Увеличивает произовдство строчек кода на 10% для младшего, среднего и страшего программистов",
            },
            [EQUIPMENT_IDS.IMPROVED_MONITOR]: {
                title: "улучшенный монитор",
                count: 0,
                price: 10,
                produces: 50,
                icon: "monitor",
                maxCount: 1,
            },*/