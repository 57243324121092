import {Button, Card, CardContent, Icon, Tab, Tooltip} from "@mui/material";
import TerminalIcon from "@mui/icons-material/Terminal"
import {memo, useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IMPROVEMENT_IDS, selectImprovements, updatePriceAndCountImprovement} from "../../store/improvementsSlice";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {decrementCodeLines, initialState, setTotalBugsAndCodeLines} from "../../store/baseSlice";
import {getFunctionInstantAction, getNumberRuLocale, isFunction} from "../utils";
import {addTotalSpentCodeLines} from "../../store/statisticSlice";
import {toUpper} from "lodash/string";
import BugReportIcon from "@mui/icons-material/BugReport";
import {isNil} from "lodash";
import {selectResearchProduces} from "../../store/researchSlice";
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CustomAccordion from "../menu/accordion/CustomAccordion";
import CustomAccordionSummary from "../menu/accordion/CustomAccordionSummary";

const sxTab = {
    color: "rgba(255, 255, 255, 0.85)",
    textTransform: 'none',
};

const sxButton = {
    width: "100px",
    minWidth: "100px",
    textTransform: "none",
    justifyContent: "center",
    "&.Mui-disabled": {
        color: "#909090"
    }
};

function CustomIcon({icon}) {
    return (<Icon className="!text-3xl/[1]">{icon}</Icon>);
}

function ImprovementTitle({value, isLock}) {
    return (
        <div className="flex gap-4 w-full items-center">
            <img className="w-[48px] h-[48px]" alt="icon" src="/images/48x48.png"/>
            <div className="flex w-full items-center justify-between">
                <div className="flex flex-col items-start gap-1 mr-2">
                    <span className="text-left">{value?.title}</span>
                    <div className="flex items-center gap-1">
                        <TerminalIcon/><span>{getNumberRuLocale(value?.price)}</span>
                    </div>
                </div>
                <div className="text-xl/[normal]">
                    {value?.maxCount
                        ? value?.maxCount === value?.count
                            ? <CheckCircleIcon className="!text-2xl/[normal] text-green-600"/>
                            : value?.maxCount > 1
                                ? `${value?.count}/${value?.maxCount}`
                                : ""
                        : value?.count}
                </div>
            </div>
            {isLock && <div className="absolute flex w-full h-full left-0 top-0 items-center justify-center">
                <LockIcon className="!text-3xl text-white/[0.85]"/>
            </div>}
        </div>
    );
}

const CustomButton = memo(function CustomButton({valueKey, value, iconName, buyImprovement, keyImprovements}) {
    const disabled = useSelector((state) => state?.base?.codeLines < value?.price);
    const isLock = false;
    const image = value?.image ?? "48x48";

    return (
        <Card variant="outlined" style={{backgroundColor: "rgba(51, 56, 67, 0.85)"}}>
            <div className="text-white/[0.85]">
                <div className="flex gap-4 w-full items-center p-4">
                    <img className="w-[64px]" alt="image" src={`/images/${image}.png`}/>
                    <div className="flex w-full items-center justify-between">
                        <div className="flex flex-col items-start gap-1 mr-2">
                            <span className="text-left">{value?.title}</span>
                            <div className="flex items-center gap-1">
                                <TerminalIcon/><span>{getNumberRuLocale(value?.price)}</span>
                            </div>
                        </div>
                        <div className="text-xl/[normal]">
                            {value?.maxCount
                                ? value?.maxCount === value?.count
                                    ? <CheckCircleIcon className="!text-2xl/[normal] text-green-600"/>
                                    : value?.maxCount > 1
                                        ? `${value?.count}/${value?.maxCount}`
                                        : ""
                                : value?.count}
                        </div>
                    </div>
                    {isLock && <div className="absolute flex w-full h-full left-0 top-0 items-center justify-center">
                        <LockIcon className="!text-3xl text-white/[0.85]"/>
                    </div>}
                </div>
                <div className="flex justify-between items-center gap-4 p-4 border-t-[1px] border-t-black/[0.12]">
                    <div className="flex gap-2">
                        {!isNil(value?.tooltip) && <span>{value?.tooltip}</span>}
                        {(!isNil(value?.produces) && value?.produces > 0) && <span
                            className="flex items-end gap-1">{`${value?.produces > 0 ? '+' : ''}${getNumberRuLocale(value?.produces * (value?.modifierProduces ?? 1))}`}<TerminalIcon
                            className="!text-xl"/></span>}
                        {!isNil(value?.bugs) && <span
                            className="flex items-end gap-1">{`${value?.bugs > 0 ? '+' : ''}${getNumberRuLocale(value?.bugs * (value?.modifierBugs ?? 1))}`}<BugReportIcon
                            className="!text-xl"/></span>}
                    </div>
                    <Button
                        key={valueKey}
                        variant="contained"
                        color="primary"
                        onClick={() => buyImprovement(keyImprovements, valueKey)}
                        disabled={disabled || value?.count >= value?.maxCount}
                        sx={sxButton}
                    >
                        {keyImprovements === IMPROVEMENT_IDS.EMPLOYEES ? "Нанять" : "Купить"}
                    </Button>
                </div>
            </div>
        </Card>
    );
    /*  return (
          <CustomAccordion title={<ImprovementTitle value={value} isLock={isLock}/>} disabled={disabled}>
              <div className="flex justify-between items-center gap-4">
                  <div className="flex gap-2">
                      {!isNil(value?.tooltip) && <span>{value?.tooltip}</span>}
                      {(!isNil(value?.produces) && value?.produces > 0) && <span
                          className="flex items-end gap-1">{`${value?.produces > 0 ? '+' : ''}${getNumberRuLocale(value?.produces * (value?.modifierProduces ?? 1))}`}<TerminalIcon
                          className="!text-xl"/></span>}
                      {!isNil(value?.bugs) && <span
                          className="flex items-end gap-1">{`${value?.bugs > 0 ? '+' : ''}${getNumberRuLocale(value?.bugs * (value?.modifierBugs ?? 1))}`}<BugReportIcon
                          className="!text-xl"/></span>}
                  </div>
                  <Button
                      key={valueKey}
                      variant="contained"
                      color="primary"
                      onClick={() => buyImprovement(keyImprovements, valueKey)}
                      disabled={disabled || value?.count >= value?.maxCount}
                      sx={sxButton}
                  >
                      Купить
                  </Button>
              </div>
          </CustomAccordion>
      );*/
    /*    return (
            <Tooltip placement="left" title={<div className="flex flex-col">
                <span>{toUpper(value?.title)}</span>
                {!isNil(value?.tooltip) && <span>{value?.tooltip}</span>}
                {(!isNil(value?.produces) && value?.produces > 0) && <span className="flex items-center gap-1">{`${value?.produces > 0 ? '+' : ''}${getNumberRuLocale(value?.produces * (value?.modifierProduces ?? 1))}`}<TerminalIcon className="!text-xl"/></span>}
                {!isNil(value?.bugs) && <span className="flex items-center gap-1">{`${value?.bugs > 0 ? '+' : ''}${getNumberRuLocale(value?.bugs * (value?.modifierBugs ?? 1))}`}<BugReportIcon className="!text-xl"/></span>}
            </div>}>
                <span>
                    <Button
                        key={valueKey}
                        variant="contained"
                        color="primary"
                        startIcon={<img width="48px" height="48px" alt="icon" src="http://via.placeholder.com/48x48" />}
                        onClick={() => buyImprovement(keyImprovements, valueKey)}
                        disabled={disabled || value?.count >= value?.maxCount}
                        sx={sxButton}
                    >
                        <div className="flex w-full items-center justify-between">
                            <div className="flex flex-col items-start gap-1 mr-2">
                                <span className="text-left">{value?.title}</span>
                                <div className="flex items-center gap-1">
                                    <TerminalIcon /><span>{getNumberRuLocale(value?.price)}</span>
                                </div>
                            </div>
                            <div className="text-xl/[normal]">{value?.maxCount ? value?.maxCount === value?.count ? <CheckCircleIcon className="!text-2xl/[normal] text-green-600"/> : value?.maxCount > 1 ? `${value?.count}/${value?.maxCount}` : "" : value?.count}</div>
                        </div>
                        {isLock && <div className="absolute flex w-full h-full left-0 top-0 items-center justify-center">
                            <LockIcon className="!text-3xl text-white/[0.85]"/>
                        </div>}
                    </Button>
                </span>
            </Tooltip>
        );*/
});

function ImprovementWindow() {
    const dispatch = useDispatch();

    const improvements = useSelector(selectImprovements);
    //const researchProduces = useSelector(selectResearchProduces);

    const [tab, setTab] = useState(Object.keys(improvements)?.[0]);

    const handleChange = useCallback((event, newValue) => setTab(newValue), [setTab]);

    const buyImprovement = useCallback((idImprovements, id) => {
        const improvement = improvements?.[idImprovements]?.values?.[id];
        const instantAction = getFunctionInstantAction(dispatch, improvement?.instantAction);
        dispatch(decrementCodeLines(improvement?.price));
        dispatch(addTotalSpentCodeLines(improvement?.price));
        dispatch(updatePriceAndCountImprovement({idImprovements, id}));
        if (isFunction(instantAction)) {
            instantAction(improvement?.variableInstantAction);
        }
    }, [dispatch, improvements]);

/*
    useEffect(() => {
        const totalBugsAndCodeLines = Object.keys(improvements).reduce((acc, cur) => {
            const improvementValues = improvements?.[cur]?.values;
            const {
                improvementValuesProduces,
                improvementValuesBugs
            } = Object.keys(improvementValues).reduce((acc, cur) => {
                const produces = improvementValues?.[cur]?.produces ?? 0;
                const bugs = improvementValues?.[cur]?.bugs ?? 0;
                const count = improvementValues?.[cur]?.count ?? 0;
                const modifierProduces = improvementValues?.[cur]?.modifierProduces ?? 1;
                const modifierBugs = improvementValues?.[cur]?.modifierBugs ?? 1;

                return {
                    improvementValuesProduces: acc?.improvementValuesProduces + count * produces * modifierProduces,
                    improvementValuesBugs: acc?.improvementValuesBugs + count * bugs * modifierBugs,
                }
            }, {improvementValuesProduces: 0, improvementValuesBugs: 0});

            return {
                codeLinesPerSeconds: acc?.codeLinesPerSeconds + improvementValuesProduces,
                totalBugsPerSeconds: acc?.totalBugsPerSeconds + improvementValuesBugs,
            };
        }, {codeLinesPerSeconds: initialState.codeLinesPerSeconds + researchProduces, totalBugsPerSeconds: initialState.totalBugsPerSeconds});

        dispatch(setTotalBugsAndCodeLines(totalBugsAndCodeLines));
    }, [improvements, researchProduces]);
*/

    return (
        <>
            <TabContext value={tab}>
                <TabList variant="scrollable" scrollButtons={false} className="px-4 border-b-[1px] border-b-black/[0.12]"
                         onChange={handleChange}>
                    {Object.keys(improvements).map((key) =>
                        (<Tab key={key} sx={sxTab} label={improvements[key]?.title} value={key}/>)
                    )}
                </TabList>
                {Object.keys(improvements).map((keyImprovements) => {
                    const improvementValues = improvements[keyImprovements]?.values;

                    return (
                        <TabPanel key={keyImprovements} className="!p-0 overflow-auto mb-4" value={keyImprovements}>
                            <div className="flex gap-2 flex-col justify-start !p-4 !pt-2 !pb-0">
                                {Object.keys(improvementValues).map((key) => {
                                    const value = improvementValues[key];
                                    const iconName = value?.icon ?? 'error';

                                    return (
                                        <CustomButton
                                            key={key}
                                            valueKey={key}
                                            buyImprovement={buyImprovement}
                                            iconName={iconName}
                                            keyImprovements={keyImprovements}
                                            value={value}
                                        />
                                    );
                                })}
                            </div>
                        </TabPanel>
                    );
                })}
            </TabContext>
        </>
    );
}

export default ImprovementWindow;
