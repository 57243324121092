import {initialState as improvementsInitialState} from "./improvementsSlice";
import {initialState as statisticInitialState} from "./statisticSlice";
import {initialState as tasksInitialState} from "./tasksSlice";
import {initialState as settingsInitialState} from "./settingsSlice";
import {initialState as baseInitialState} from "./baseSlice";
import {initialState as researchInitialState} from "./researchSlice";

export const initState = {
    base: baseInitialState,
    improvements: improvementsInitialState,
    statistic: statisticInitialState,
    tasks: tasksInitialState,
    settings: settingsInitialState,
    research: researchInitialState,
};