import {
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    IconButton,
    Slide,
    Tooltip,
    Checkbox, Button, Popper, ClickAwayListener, useTheme, useMediaQuery
} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import HelpIcon from "@mui/icons-material/Help";
import CloseIcon from "@mui/icons-material/Close";
import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    resetBgColorTheme, selectEnableAutoSaveConfig,
    selectShowCodeLinesInTab,
    setBgColorTheme, toggleEnableAutoSaveConfig,
    toggleShowCodeLinesInTab
} from "../../store/settingsSlice";
import {Colorful} from "@uiw/react-color";

const PaperProps = {
    style: {
        backgroundColor: '#282c34',
        minHeight: "600px",
    },
};

const TransitionProps = {direction: "up", timeout: 500};

const sx = {
    position: 'absolute',
    right: 8,
    top: 8,
    color: (theme) => theme.palette.grey[500],
};

function SimplePopper() {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [color, setColor] = useState(theme.palette.primary.main);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            <div>
                <Button type="button" sx={{
                    margin: "1rem",
                    padding: "12px",
                    backgroundColor: theme.palette.primary.main,
                    '&:hover': {
                        backgroundColor: theme.palette.primary.main,
                    },
                    border: "1px solid #464646",
                }} onClick={handleClick}></Button>
                <Popper keepMounted className="z-[10000] bg-[#272727] rounded-xl" id={id} open={open} anchorEl={anchorEl}>
                    <div className="flex flex-col gap-3 p-6">
                        <div style={{backgroundColor: color}} className="rounded-md h-8 border-[1px] border-[#464646]"></div>
                        <Colorful color={color} onChange={(color) => {
                            setColor(color.hexa);
                        }}/>
                        <Button onClick={() => {setAnchorEl(null); dispatch(setBgColorTheme(color))}} variant="contained">Сохранить</Button>
                        <Button onClick={() => {setAnchorEl(null); dispatch(resetBgColorTheme())}} variant="contained">Сброс</Button>
                    </div>
                </Popper>
            </div>
        </ClickAwayListener>
    );
}

function SettingsButton() {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const showCodeLinesInTab = useSelector(selectShowCodeLinesInTab);
    const enableAutoSaveConfig = useSelector(selectEnableAutoSaveConfig);
    const [open, setOpen] = useState(false);

    const handleOpenDialog = useCallback(() => setOpen(true), []);

    const handleCloseDialog = useCallback(() => setOpen(false), []);

    const onChangeShowLineCodeInTab = useCallback(() => {
        dispatch(toggleShowCodeLinesInTab());
    }, []);

    const onChangeEnableAutoSaveConfig = useCallback(() => {
        dispatch(toggleEnableAutoSaveConfig());
    }, []);

    return (
        <>
            <IconButton
                variant="contained"
                className="!rounded-[10%]"
                color="primary"
                onClick={handleOpenDialog}
                sx={{
                    "&:hover": {
                        backgroundColor: (theme) => theme.palette.primary.main,
                    },
                    backgroundColor: (theme) => theme.palette.primary.main,
                    color: (theme) => theme.palette.primary.contrastText
                }}
            >
                <SettingsIcon className="!text-2xl"/>
            </IconButton>
            <Dialog
                TransitionComponent={Slide}
                TransitionProps={TransitionProps}
                fullWidth
                maxWidth="sm"
                open={open}
                PaperProps={{
                    style: {
                        backgroundColor: '#282c34',
                        height: fullScreen ? "100%" : "600px",
                    },
                }}
                fullScreen={fullScreen}
            >
                <DialogTitle className="text-white/[0.85]">
                    <div className="flex gap-1 items-center">
                        <span>Настройки</span>
                        <Tooltip
                            enterTouchDelay={0}
                            placement="bottom-start"
                            title="В этом модальном окне вы можете изменять настройки игры"
                        >
                            <HelpIcon className="!text-xl"/>
                        </Tooltip>
                    </div>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseDialog}
                    sx={sx}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogContent dividers className="text-white/[0.85] flex flex-col gap-2 !p-0 !overflow-hidden">
                    <FormControl className="items-start">
                        <FormControlLabel
                            className="flex justify-between pl-6 !m-0 w-full border-b-[1px] border-black/[0.12]"
                            control={<SimplePopper />}
                            labelPlacement="start"
                            label="Цвет темы"
                        />
                        <FormControlLabel
                            control={<Checkbox
                                className="ml-2"
                                sx={{color: "rgb(255 255 255 / 0.85)", margin: "1rem", padding: 0}}
                                checked={enableAutoSaveConfig}
                                onChange={onChangeEnableAutoSaveConfig}
                            />}
                            className="flex justify-between pl-6 !m-0 w-full border-b-[1px] border-black/[0.12]"
                            labelPlacement="start"
                            label="Включить автосохранение"
                        />
                        <FormControlLabel
                            control={<Checkbox
                                className="ml-2"
                                sx={{color: "rgb(255 255 255 / 0.85)", margin: "1rem", padding: 0}}
                                checked={showCodeLinesInTab}
                                onChange={onChangeShowLineCodeInTab}
                            />}
                            className="flex justify-between pl-6 !m-0 w-full border-b-[1px] border-black/[0.12]"
                            labelPlacement="start"
                            label="Показывать количество строчек кода в названии вкладки"
                        />
                    </FormControl>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default SettingsButton;