import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {Dialog, DialogContent, DialogTitle, IconButton, Slide, Tooltip, useMediaQuery, useTheme} from "@mui/material";
import {useState, useCallback} from "react";
import ImprovementWindow from "../window/ImprovementWindow";
import HelpIcon from "@mui/icons-material/Help";
import CloseIcon from "@mui/icons-material/Close";
import StoreIcon from '@mui/icons-material/Store';

const PaperProps = {
    style: {
        backgroundColor: '#282c34',
        minHeight: "600px",
    },
};

const TransitionProps = { direction: "up", timeout: 500 };

const sx = {
    position: 'absolute',
    right: 8,
    top: 8,
    color: (theme) => theme.palette.grey[500],
};

function ShopButton() {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = useState(false);

    const handleOpenDialog = useCallback(() => setOpen(true), []);

    const handleCloseDialog = useCallback(() => setOpen(false), []);

    return (
        <>
            <IconButton
                variant="contained"
                className="!rounded-[10%]"
                color="primary"
                onClick={handleOpenDialog}
                sx={{
                    "&:hover": {
                        backgroundColor: (theme) => theme.palette.primary.main,
                    },
                    backgroundColor: (theme) => theme.palette.primary.main,
                    color: (theme) => theme.palette.primary.contrastText
                }}
            >
                <StoreIcon className="!text-2xl"/>
            </IconButton>
            <Dialog
                TransitionComponent={Slide}
                TransitionProps={TransitionProps}
                fullWidth
                maxWidth="sm"
                open={open}
                PaperProps={{
                    style: {
                        backgroundColor: '#282c34',
                        height: fullScreen ? "100%" : "600px",
                    },
                }}
                fullScreen={fullScreen}
            >
                <DialogTitle className="text-white/[0.85]">
                    <div className="flex gap-1 items-center">
                        <span>Магазин</span>
                        <Tooltip
                            enterTouchDelay={0}
                            placement="bottom-start"
                            title="В этом модальном окне вы можете приобрести улучшения и нанять новых сотрудников"
                        >
                            <HelpIcon className="!text-xl"/>
                        </Tooltip>
                    </div>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseDialog}
                    sx={sx}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers className="text-white/[0.85] flex flex-col gap-2 !p-0 !overflow-hidden">
                    <ImprovementWindow />
                </DialogContent>
            </Dialog>
        </>
    );
}

export default ShopButton;