import {useEffect, useLayoutEffect, useRef, useState} from "react";

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

function MatrixEffect() {
    const [width, height] = useWindowSize();
    const ref = useRef(null);

    useEffect(() => {
        const canvas = ref.current;
        const context = canvas.getContext("2d");
        canvas.width = width;
        canvas.height = height;
        context.fillStyle = "rgba(0,0,0,1)";
        context.fillRect(0, 0, width, height);

        const str = "А+Б0В-Г1Д=Е2Ё Ж3З И4Й К5Л М6Н О7П Р8С Т9У Ф!Х Ц?Ч Ш.ЩЪ,Ы Ь:ЭЮ;Я";
        const matrix = str.split("");

        const font = 11;
        let columns = width / font;
        let arr = [];

        for (let i = 0; i < columns; i++) {
            arr[i] = 1;
            //arr[i] = height;
        }

        const draw = () => {
            context.fillStyle = "rgba(0,0,0,.1)";
            context.fillRect(0, 0, width, height);
            context.fillStyle = "#0f0";
            context.font = font + "px system-ui";
            for (let i = 0; i < arr.length; i++) {
                let txt = matrix[Math.floor(Math.random() * matrix.length)];
                context.fillText(txt, i * font, arr[i] * font);
                if (arr[i] * font > height && Math.random() > 0.975) {
                    arr[i] = 0;
                }
                arr[i]++;
            }
        };

        const interval = setInterval(draw, 75);

        /*  const handleResize = () => {
       /*     //location.reload();
              width = (context.width = window.innerWidth);
              height = (context.height = window.innerHeight);
              arr = [];
              columns = width / font;
              for (let i = 0; i < columns; i++) arr[i] = 1;
              //clearInterval(interval);
          };

        window.addEventListener("resize", handleResize);
    */
        return () => {
            clearInterval(interval);
            //window.removeEventListener("resize", handleResize);
        };
    }, [width, height]);

    return (
        <canvas ref={ref} className="absolute left-0 top-0"/>
    );
}

export default MatrixEffect;